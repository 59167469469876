import FSender_Worker_180718_052734 from "./fsender_worker_180718_052734"

export default class FSender_180718_053026 {
    constructor() {
        // alert("FSender_180718_053026.constructor() >>>>");
        this.elixir_websocket = null;
        this.fileSelector = null;
        this.fileSelected = null;
        this.file_sender_worker = null;
        this.messagesContainer = null;
        this.msg_count = 0;
        this.file_upload_ongoing_list = [];
        this.file_upload_wiating_list = [];
        this.MAX_ON_TRANSFER_COUNT = 32;
        // this.fileSelector_180707_160044 = null;
    }
    get_uuid(){
        let dt = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }
    init(elixir_websocket) {
        // alert("FSender_180704_100138.init() >>>>" + this.get_uuid());
        this.elixir_websocket = elixir_websocket;
        this.messagesContainer = document.querySelector("#elixir-fsender-test-180718-004956");
        // let messageItem = document.createElement("li");
        // messageItem.innerText = `[${Date()}] >>>> done`;
        // this.messagesContainer.appendChild(messageItem);
        this.fileSelector = document.querySelector("#file-select-180718-005022");
        // this.fileSelector.onchange = (event) => {this.on_file_select_change(event)}; // <=== do not use it!!!
        // ////////////////////////////////////////////////////////////////////////////////////////////
        this.fileSelector.addEventListener('change', event => {this.on_file_select_change(event)});
        // ////////////////////////////////////////////////////////////////////////////////////////////
        // this.fileSelector_180707_160044 = document.querySelector("#file-select-180707-155854");
        // // this.fileSelector_180707_160044.addEventListener('change', event => {this.on_file_select_change(event)});
        this.file_sender_worker = new FSender_Worker_180718_052734();
    }
    on_file_select_change(event){
        // alert("FSender_180718_053026.on_file_select_change() >>>>");
        let fileSelected = document.querySelector('#file-select-180718-005022').files[0];
        // this.send_file_in_sequence(fileSelected);   //<==== send one by one
        // //////////////////////////////////////////////
        this.send_file_in_parallel(fileSelected);      //<==== send together
        // //////////////////////////////////////////////
    }
    send_file_in_parallel(fileSelected){
        // alert("FSender_180718_053026.send_file_in_parallel(fileSelected) >>>>");
        let uuid = this.get_uuid();
        if(this.file_upload_ongoing_list.length < this.MAX_ON_TRANSFER_COUNT){
            let file_sender_worker = new FSender_Worker_180718_052734();
            file_sender_worker.send_file(fileSelected, uuid, this);
            this.file_upload_ongoing_list.push(file_sender_worker);
            // alert("File_Uploader_Manager_180506_142955.file_upload_ongoing_list.length >>>>" + this.file_upload_ongoing_list.length);
            // //////////////////////////////////
            fileSelected = null;
            file_sender_worker = null;
            // //////////////////////////////////
        }else{
            this.file_upload_wiating_list.push({uuid:uuid, file:fileSelected});
            // alert("File_Uploader_Manager_180506_142955.file_upload_wiating_list.length >>>>" + this.file_upload_wiating_list.length);
            // alert("file_name >>> " + this.file_upload_wiating_list[this.file_upload_wiating_list.length-1].file.name);
        }
    }
    on_file_uploaded(uuid, file_name){
        // alert("File_Uploader_Manager_180506_142955.on_file_uploaded(uuid, file_name) >>>> " + file_name);
        let i =0;
        for(i=0; i<this.file_upload_ongoing_list.length; i++){
            if(this.file_upload_ongoing_list[i].uuid === uuid){
                // ////////////////////////////////////////////////////
                this.file_upload_ongoing_list[i].fileSelected = null;
                // ////////////////////////////////////////////////////
                this.file_upload_ongoing_list.splice(i, 1);
                break;
            }
        }
        if((this.file_upload_ongoing_list.length < this.MAX_ON_TRANSFER_COUNT)&&(this.file_upload_wiating_list.length > 0)){
            let fileSelected = this.file_upload_wiating_list[0].file;
            let uuid = this.file_upload_wiating_list[0].uuid;
            let file_sender_worker = new FSender_Worker_180718_052734();
            file_sender_worker.send_file(fileSelected, uuid, this);
            this.file_upload_ongoing_list.push(file_sender_worker);
            this.file_upload_wiating_list.splice(0, 1);
            // alert("File_Uploader_Manager_180506_142955.file_upload_ongoing_list.length >>>>" + this.file_upload_ongoing_list.length);
        }
    }    
}