export default class FSender_Worker_180718_052734 {
    constructor() {
        // alert("FSender_Worker_180718_052734.constructor() >>>>");
        this.fileSelected = null;
        this.uuid = null;
        this.channel = null;
        this.fileReader = new FileReader();
        this.fileReader.onload = () => {this.on_reader_load_file_trunks();};
        this.totalBytesSend = 0;
        this.chunkID = 0;
        this.cs = 0;
        this.MAX_FILE_CHUNK_SIZE = 8 * 1024;  // 8 * 1024 <== if use 1M, android smartphone can't upload big files
        this.CHUNKS_COUNT_TO_STOP = 10;          // need to synchronize with server (see: room_channel.ex)
        this.file_upload_wiating_list = [];
        this.messagesContainer = null;
        this.messagesContainer = document.querySelector("#elixir-fsender-test-180718-004956");
        this.file_sender = null;
        this.isLastChunk = null;
    }
    get_uuid(){
        let dt = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }
    ascii_string_to_arraybuffer(payload){
        // alert('>>>>> convert_payload_to_arraybuffer(payload)');
        // alert('>>>>> payload.length : ' + payload.length);
        let array_buffer = new ArrayBuffer(payload.length);
        let uint8array = new Uint8Array(array_buffer);
        for (let i = 0; i < payload.length; i++) {
            uint8array[i] = payload.charCodeAt(i);
        }
        // -----------------------------------------------------------------------
        // <h1>Verify the data is correct</h1>
        // ----------------------------------------------------------------------
        // // ArrayBuffer -> blob
        // let blob = new Blob([uint8array], {type: 'application/octet-stream'});
        // // var url = URL.createObjectURL(blob);
        
        // // // [blob test] blob -> FileReader
        // // let preview = document.querySelector('img');
        // // let reader  = new FileReader();
        // // reader.onload = () => {
        // //     preview.src = reader.result;
        // // };
        // // reader.readAsDataURL(blob);

        // // [blob test] blob -> file -> send to server
        // let fileSelected = new File([blob], payload.name);
        // this.file_receiver.file_uploader_manager.send_file_in_parallel(fileSelected);

        return array_buffer;
    }
    concatenate_two_arraybuffers(buffer1, buffer2){
        let tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
        tmp.set(new Uint8Array(buffer1), 0);
        tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
        return tmp.buffer;
    }
    concatenate_arraybuffers(buffer_list){
        let len = 0;
        for(let i=0; i<buffer_list.length; i++){
            len += buffer_list[i].byteLength;
        }
        if(len === 0){
            return null;
        }else{
            let tmp = new Uint8Array(len);
            let index = 0;
            for(let i=0; i<buffer_list.length; i++){
                tmp.set(new Uint8Array(buffer_list[i]), index); 
                index += buffer_list[i].byteLength;
            }
            return tmp.buffer;
        }
    }
    on_reader_load_file_trunks(){
        // let fileReaderResult = this.fileReader.result;
        // let byte_array = new Uint8Array(this.fileReader.result);  
        // alert('byte_array.byteLength >>>>>>>>>>>>>> ' + byte_array.byteLength);
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if(this.fileReader.result === null){
            // alert("[" + this.fileSelected.name + "] fileReaderResult == null >>>>> , this.totalBytesSend: " + this.totalBytesSend);
        }
        if(this.cs == 0){
            // alert("[" + this.fileSelected.name + "] this.cs == 0 >>>>> , this.totalBytesSend: " + this.totalBytesSend);
        }
        if(this.fileReader.result === undefined){
            // alert("[" + this.fileSelected.name + "] typeof fileReaderResult == \"undefined\" >>>>> , this.totalBytesSend: " + this.totalBytesSend);
            // alert("[" + this.fileSelected.name + "] this.cs: " + this.cs);
            // alert("[" + this.fileSelected.name + "] this.chunkID: " + this.chunkID);
            // alert("[" + this.fileSelected.name + "] chunk: " + fileReaderResult);
        }
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if(this.cs > 0){                         //[180504-174426 Fix Issue] FileReader return undefined, and no data
            if(this.fileReader.result === undefined){    //resend the file
                let uuid = this.uuid;
                this.uuid = null;
                this.send_file(this.fileSelected, uuid, this.file_sender);
            }else{
                if(this.file_sender.elixir_websocket.channel !== null){
                    let file_name_uri_component_encoded = encodeURIComponent(this.fileSelected.name);
                    let jason = JSON.stringify({
                        name: file_name_uri_component_encoded,
                        dir: "", 
                        chunk: "", 
                        mid: "180427-120518", 
                        uuid: this.uuid, 
                        cid: this.chunkID, 
                        cs: this.cs, 
                        fs: this.fileSelected.size, 
                        ilc: this.isLastChunk,
                        mcs: this.MAX_FILE_CHUNK_SIZE,
                        cc:1,
                        ct:"",
                        at:"",
                        mt:"",
                        pms:755,
                        sas:"",
                        wm:"ow"});
                    let array_buf_info = this.ascii_string_to_arraybuffer(jason);
                    let array_buf_chunk = this.fileReader.result;
                    let array_buf_list = [];
                    array_buf_list.push(array_buf_info);
                    array_buf_list.push(array_buf_chunk);
                    let array_buf = this.concatenate_arraybuffers(array_buf_list);
                    this.file_sender.elixir_websocket.channel.send(array_buf);

                    this.totalBytesSend += this.cs;
                    this.chunkID += 1;
                }
            }
        }
        let remainingBytesToSend = 0;
        remainingBytesToSend = this.fileSelected.size - this.totalBytesSend;
        if(remainingBytesToSend > 0) {
            if(remainingBytesToSend <= this.cs) {
                this.cs =  remainingBytesToSend;
                this.isLastChunk = true;
                // this.chunkID = 0;
            }
            // if((this.chunkID == 0)||((this.chunkID-1)%(this.CHUNKS_COUNT_TO_STOP)!= 0)){
            if((this.isLastChunk == true)||((this.chunkID-1)%(this.CHUNKS_COUNT_TO_STOP)!= 0)){
                let blob = this.fileSelected.slice(this.totalBytesSend, this.totalBytesSend + this.cs);
                this.fileReader.readAsArrayBuffer(blob);
            }
        }
        else{
            // alert("File transfer to client push buffer done >>>");
        }
    }
    on_elixir_in(event){
        // alert("FSender_Worker_180704_100224.on_elixir_in(event) >>>");
        if(typeof(event.data) === 'string'){
            let payload = JSON.parse(event.data); 
            // alert('msg.mid >>>>> ' + payload.mid);
            if((payload.mid == "180427-120518")&&(payload.uuid == this.uuid)&&(payload.ilc != true)){
                this.send_file_chunks();
            }else if((payload.mid == "180427-120518")&&(payload.uuid == this.uuid)&&(payload.ilc == true)){
                // alert("File transfer done >>>");
                if(this.uuid !== null){                
                    this.file_sender.on_file_uploaded(payload.uuid, payload.name);
                    let fName = decodeURIComponent(payload.name);
    
                    let messageItem = document.createElement("li");
                    // messageItem.innerText = `[${Date()}] ${payload.chunk}`;
                    // messageItem.innerText = `[${Date()}] ${payload.mid}`;
                    messageItem.innerText = `[${Date()}] ${fName} >>>> done`;
                    this.messagesContainer.appendChild(messageItem);
    
                    this.uuid = null;
                    if(this.file_upload_wiating_list.length > 0){
                        let file = this.file_upload_wiating_list[0].file;
                        let uuid = this.file_upload_wiating_list[0].uuid;
                        this.file_upload_wiating_list.splice(0, 1);
                        this.send_file(file, uuid, this.file_sender);
                    }
                }
            }
        }
    }
    send_file(file, uuid, file_sender) {
        // alert("FSender_Worker_180718_052734.send_file(file, uuid, file_sender) >>>");
        if(this.uuid === null){
            this.fileSelected = file;
            // /////////////////////
            file = null;
            // /////////////////////
            // alert('this.fileSelected >>>> ' + this.fileSelected);
            this.uuid = uuid;
            this.file_sender = file_sender;
            this.channel = file_sender.elixir_websocket.channel;
            this.totalBytesSend = 0;
            this.chunkID = 1;
            this.cs = this.MAX_FILE_CHUNK_SIZE;
            this.isLastChunk = false;
            this.file_sender.elixir_websocket.ws.addEventListener('message', event => {this.on_elixir_in(event)});
            if(this.fileSelected.size > Number.MAX_SAFE_INTEGER){
                alert("fileSelected.size > Number.MAX_SAFE_INTEGER");            
            }else{
                this.send_file_chunks();
            } 
        }else{
            this.file_upload_wiating_list.push({file:file, uuid:uuid});
        }
    }
    send_file_chunks(){
        // alert("FSender_Worker_180709_165302.send_file_chunks() >>>>");
        let remainingBytesToSend = 0;
        if (this.fileSelected) {
            remainingBytesToSend = this.fileSelected.size - this.totalBytesSend;
            if(remainingBytesToSend <= this.cs) {
                this.cs = remainingBytesToSend;
                this.isLastChunk = true;
                // this.chunkID = 0;
            }
            let blob = this.fileSelected.slice(this.totalBytesSend, this.totalBytesSend + this.cs);
            this.fileReader.readAsArrayBuffer(blob);
        }else{

        }
    }
}