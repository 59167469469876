import File_Reader_180709_202430 from "./file_reader_180709_202430"
import FReceiver_180725_083440 from "./freceiver_180725_083440"

export default class FReceive_Notifier_180725_073856 {
    constructor() {
        // alert("FReceive_Notifier_180725_073856.constructor() >>>>");
        // this.phoenix_websocket = null;
        this.elixir_websocket = null;
        this.channel = null;
        this.file_receiver = new FReceiver_180725_083440();
        this.receive_file_in_db_notification_list = [];
    }
    init(elixir_websocket){
        // this.phoenix_websocket = phoenix_websocket;
        // this.channel = phoenix_websocket.channel;
        this.elixir_websocket = elixir_websocket;
        this.file_receiver.onreceive_file_in_db = (event) => {this.on_receive(event, 'file_in_db')};
        this.file_receiver.onreceive_file_in_mem = (event) => {this.on_receive(event, 'file_in_mem')};
        this.file_receiver.init(elixir_websocket);
    }
    // init(phoenix_websocket, elixir_websocket){
    //     this.phoenix_websocket = phoenix_websocket;
    //     this.channel = phoenix_websocket.channel;
    //     this.elixir_websocket = elixir_websocket;
    //     this.file_receiver.onreceive_file_in_db = (event) => {this.on_receive(event, 'file_in_db')};
    //     this.file_receiver.onreceive_file_in_mem = (event) => {this.on_receive(event, 'file_in_mem')};
    //     this.file_receiver.init(phoenix_websocket, elixir_websocket);
    // }
    on_receive(event, file_in_db_or_mem){
        if(file_in_db_or_mem === 'file_in_mem'){
            // alert("FReceive_Notifier_180725_073856.on_receive(event, file_in_db_or_mem) >>>>");
            let callback_list = [];
            for(let i = 0; i < this.receive_file_in_db_notification_list.length; i++){
                // if(this.receive_file_in_db_notification_list[i].path === (event.dir + "/" + event.name)){
                //     this.receive_file_in_db_notification_list[i].callback(event);
                // }
                if(this.receive_file_in_db_notification_list[i].path === (event.sas)){
                    // alert('this.receive_file_in_db_notification_list[i].path === (event.sas) >>>> ' + event.sas);
                    let file_reader = new File_Reader_180709_202430();
                    file_reader.onread_mem = (event) => {
                        // alert('(file_reader.folder_index, file_reader.file_index)   >>>> ' + file_reader.folder_index + " " + file_reader.file_index);
                        if((file_reader.folder_index !== null)&&(file_reader.file_index !== null)){
                            if(this.receive_file_in_db_notification_list[i].request_delete_after_read === true){
                                this.file_receiver.file_list[file_reader.folder_index].file_list[file_reader.file_index].count_of_request_delete_after_read += 1;
                            }
                        }
                    }
                    file_reader.read(event.sas, "mem", this.file_receiver.file_list, false);
                    callback_list.push(this.receive_file_in_db_notification_list[i]);
                }
            }
            for(let y = 0; y < callback_list.length; y++){
                callback_list[y].callback(event);
            }
        }else if(file_in_db_or_mem === 'file_in_db'){

        }else{

        }
    }
    register(callback, file_in_db_or_mem){
        if(file_in_db_or_mem === 'file_in_mem'){
            this.receive_file_in_db_notification_list.push(callback);
        }else if(file_in_db_or_mem === 'file_in_db'){

        }else{

        }
    }
    unregister(callback, file_in_db_or_mem){
        if(file_in_db_or_mem === 'file_in_mem'){
            for(let i = 0; i < this.receive_file_in_db_notification_list.length; i++){
                if((this.receive_file_in_db_notification_list[i].path === callback.path)&&
                   (this.receive_file_in_db_notification_list[i].callback === callback.callback)){
                    this.receive_file_in_db_notification_list.splice(i, 1);
                    break;
                }
            }
        }else if(file_in_db_or_mem === 'file_in_db'){

        }else{

        }
    }
}