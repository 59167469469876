export default class Elixir_Websocket_180718_012548 {
    constructor() {
        // alert('Elixir_Websocket_180718_012548.constructor() >>>>>>>>>>>>>>');
        this.ws = null;
        this.channel = null;
    }
    // get_uuid(){
    //     let dt = new Date().getTime();
    //     let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    //         let r = (dt + Math.random()*16)%16 | 0;
    //         dt = Math.floor(dt/16);
    //         return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    //     });
    //     return uuid;
    // }
    init(url){
        // alert(' >>>>>>>>>>>>>>' + window.location.host);
        // this.ws = new WebSocket('ws://' + window.location.host + '/ws');
        // this.ws = new WebSocket('ws://cofe-keanu.com:8000/ws');
        this.ws = new WebSocket(url);
        // this.ws.onmessage = (event) => {
        //     // ////////////////////////////////////////////////////////////////////////
        //     // alert('this.ws.onmessage: ' + event.data);
        //     // ////////////////////////////////////////////////////////////////////////
        //     // console.log('Received data: ' + event.data);
        // };
        // alert('this.ws >>>>>>>>>>>>>> ' + this.ws);
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // As establishing a connection is asynchronous and prone to failure there is no guarantee that calling the send() method immediately after creating a WebSocket object will be successful. 
        // We can at least be sure that attempting to send data only takes place once a connection is established by defining an onopen handler to do the work:
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        this.ws.onopen = (event) => {
            // //////////////////////////////////////////////////////////
            // alert('FUCK >>>>>>>>>>>>>> ' + window.location.host);
            // //////////////////////////////////////////////////////////
            this.ws.binaryType = 'arraybuffer';
            this.channel = this.ws;

            // -----------------------------------------
            // Websocket Connection Keepalive Message
            // -----------------------------------------
            // let keep_websocket_alive = 
            // setInterval(() => { 
            //     let arrayBuffer = new ArrayBuffer(2);
            //     let uint8Array = new Uint8Array(arrayBuffer);
            //     // uint8Array[0] = 0x9;
            //     uint8Array[0] = 0x30;
            //     uint8Array[1] = 0x31;
            //     this.channel.send(arrayBuffer);
            //     // this.channel.ping("hello >>>>");
            // }, 30*1000);
            // -----------------------------------------------------------
            // <h1>Test | Send Text</h1>
            // -----------------------------------------------------------
            // // // var msg = {type: 'ping', count: 1};
            // // // this.send(JSON.stringify(msg));
            // this.channel.send(JSON.stringify({
            //     name:"/home/james/Desktop/binary-data/白家綺/792278.jpg",
            //     dir:"",
            //     chunk:"",
            //     mid:"180518-062437",
            //     uuid:this.get_uuid(),
            //     cid:1,
            //     cs:0,
            //     fs:0,
            //     ilc:true,
            //     mcs:8*1024,
            //     cc:1,
            //     ct:"",
            //     at:"",
            //     mt:"",
            //     pms:777,
            //     sas:"",
            //     wm:"ow"}));
            // -----------------------------------------------------------
            // <h1>Test | Send Binary</h1>
            // -----------------------------------------------------------
            // let arrayBuffer = new ArrayBuffer(2);
            // let uint8Array = new Uint8Array(arrayBuffer);
            // uint8Array[0] = 0x30;
            // uint8Array[1] = 0x31;
            // // this.channel.send(uint8Array);
            // this.channel.send(arrayBuffer);
            // // let bufArray = new Uint8Array([0x22,0x5B]);
            // // this.channel.push("ftpCtS", bufArray);
        };
        
        // this.ws.connect();
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // this.ws.addEventListener('message', (e) => {
        //     var msg = JSON.parse(e.data);
        //     alert('FUCK >>>>>>>>>>>>>>');
        //     // document.getElementById("app_170704_043633").innerHTML += ('<pre>' + msg.message + '</pre>');
        // });
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // this.ws.onmessage = (event) => {
        //     // alert('FUCK >>>>>>>>>>>>>> 操死妳娘');
        //     // var msg = JSON.parse(event.data);
        //     alert('FUCK >>>>>>>>>>>>>> ' + event.data);
        // };
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // this.newMsg = 'Hello James >>> Fuck!\n';
        // if (this.newMsg != '') {
        //     this.ws.send(
        //         JSON.stringify({
        //             message: this.newMsg // Strip out html
        //         }));
        //     this.newMsg = ''; // Reset newMsg
        // }
    }
    // send(data) {
    //     console.log('Sending data: ' + data);
    //     this.ws.send(data);
    // }
}