// import WASM_200201_205400 from './wasm/wasm_200201_205000'
import Elixir_Websocket_180718_012548 from './websocket/socket/elixir_websocket_180718_012548'
import FSender_180718_053026 from './websocket/fsender/fsender_180718_053026'
import FReceive_Notifier_180725_073856 from './websocket/freceiver/freceive_notifier_180725_073856'
import App_Loader_180720_013150 from './app/app_loader_180720_013150'

// import init, * as cofe_keanu_200129_215000 from './wasm/pkg/cofe_keanu_200201_191900'; 

// async function run() {

//   console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 3");

//   // First up we need to actually load the wasm file, so we use the
//   // default export to inform it where the wasm file is located on the
//   // server, and then we wait on the returned promise to wait for the
//   // wasm to be loaded.
//   // It may look like this: `await init('./pkg/without_a_bundler_bg.wasm');`,
//   // but there is also a handy default inside `init` function, which uses
//   // `import.meta` to locate the wasm file relatively to js file
//   //
//   // Note that instead of a string here you can also pass in an instance
//   // of `WebAssembly.Module` which allows you to compile your own module.
//   // Also note that the promise, when resolved, yields the wasm module's
//   // exports which is the same as importing the `*_bg` module in other
//   // modes
//   await init(window.wasm_url_200201_191900);

//   // ///////////////////////////////////////////////////////////////////
//   // // window.wasm = cofe_keanu_200129_215000;
//   // window.on_wasm_open(cofe_keanu_200129_215000);
  
//   // ///////////////////////////////////////////////////////////////////
//   // [readme] And afterwards we can use all the functionality defined in wasm.
//   const result = cofe_keanu_200129_215000.add(99, 1000);
//   // console.log(`1 + 2 = ${result}`);
//   console.log(result);
//   // alert(result);
//   // // if (result !== 3)
//   // // throw new Error("wasm addition doesn't work!");

//   // ///////////////////////////////////////////////////////////////////
//   // cofe_keanu_200129_215000.greet(">>>>>>>");
//   // cofe_keanu_200129_215000.j2w("Jinny Fu");
//   // let s = ""+Math.floor(Date.now()/1000);
//   // cofe_keanu_200129_215000.j2w(s);

//   // ///////////////////////////////////////////////////////////////////
//   // cofe_keanu_200129_215000.start_websocket(); //<==============

//   // ///////////////////////////////////////////////////////////////////
//   // alert(~~(Date.now() / 1000));
//   // alert(Math.floor(Date.now()/1000));
//   // window.f_200201_200800();
//   // ///////////////////////////////////////////////////////////////////
// }
// run();

// /////////////////////////////////////////////////////////////
// [211006-021000][jamesko] not work
// /////////////////////////////////////////////////////////////
// import wasmInit from "./pkg/helloworld.js";
// runWasm = async () => {
//   // Instantiate our wasm module
//   const rustWasm = await wasmInit(
//     "./pkg/helloworld_bg.wasm"
//   );
//   // Run the exported function
//   // rustWasm.console_log_from_wasm(); // Should log "This console.log is from wasm!"
//   let s = helloworld();
//   alert(s);
// };
// runWasm();
// /////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////
// const wasm = import("./pkg/wasm_test_200125_152200");
// wasm.then(m => m.start_websocket());

// import("./pkg/wasm_test_200125_152200").then(wasmModule => {
//   wasmModule.start_websocket();
// });

// const js = import("./pkg/wasm_test_200125_152200");
// const runWasm = async () => {
//   // Instantiate our wasm module
//   const helloWorld = await js("./pkg/wasm_test_200125_152200_bg.wasm");
//   helloWorld.start_websocket();
// };
// runWasm();

// import * as wasm from "./pkg/wasm_test_200125_152200";
// // wasm.greet();
// wasm.start_websocket();

// [wasm][200126-061800][james-ko] => [not work!!]
// -------------------------------------------------
// const rust = import("./pkg/add_wasm");
// rust.then(m => m.greet("World!"));

// import * as wasm from "./pkg/hello_wasm";
// // wasm.greet("WebAssembly");
// wasm.then(wasm => {
//     wasm.greet("WebAssembly");
// });

// [not work!]
// const js = import("./pkg/hello_wasm.js");
// // js.greet("WebAssembly");
// js.then(js => {
//   js.greet("WebAssembly");
// });

// [not work!]
// // import * from "./pkg/hello_wasm";
// const js = import("./pkg/hello_wasm");
// const runWasm = async () => {
//   // Instantiate our wasm module
//   const helloWorld = await js("./pkg/hello_wasm_bg.wasm");
//   helloWorld.greet("WebAssembly");
// };
// runWasm();
// /////////////////////////////////////////////////////////////
// [not work!]
// fetch('https://cofe-keanu.com/static/app/190321-213922-yuanmei-timer-elixir-iot/build/hello_wasm_bg.wasm')
//     .then(response => response.arrayBuffer())
//     .then(bytes => WebAssembly.instantiate(bytes, {}))
//     .then(results => {
//         results.instance.exports.greet("WebAssembly");
//         // alert(results.instance.exports.greet("WebAssembly"));
// });

// [WORK!!!][james-ko][200127-153600]
// fetch('https://cofe-keanu.com/static/app/190321-213922-yuanmei-timer-elixir-iot/build/add.small.wasm')
//     .then(response => response.arrayBuffer())
//     .then(bytes => WebAssembly.instantiate(bytes, {}))
//     .then(results => {
//         alert(results.instance.exports.add_one(40));
//         let s = results.instance.exports.string_from_rust();
//         // alert(String("Just do it!"));
//         alert(String(s));
// });

// [NOT WORK]
// window.Module = {};
// fetchAndInstantiate("https://cofe-keanu.com/static/app/190321-213922-yuanmei-timer-elixir-iot/build/add.small.wasm", {})
// .then(mod => {
//   Module.fact  = mod.exports.fact;
//   Module.alloc = mod.exports.alloc;
//   Module.dealloc_str = mod.exports.dealloc_str;
//   Module.memory = mod.exports.memory;
//   Module.fact_str = function() {
//     let outptr = mod.exports.add_one(0);
//     let result = copyCStr(Module, outptr);
//     return result;
//   };
// })

// var Module = {}
// var Sha1 = {
//   digest: function(str) {
//     let buf = newString(Module, str);
//     let outptr = Module.digest(buf);
//     let result = copyCStr(Module, outptr);
//     Module.dealloc_str(buf);
//     return result;
//   }
// }
// fetchAndInstantiate("https://cofe-keanu.com/static/app/190321-213922-yuanmei-timer-elixir-iot/build/add.small.wasm", {})
// .then(mod => {
//   Module.alloc   = mod.exports.alloc;
//   Module.dealloc = mod.exports.dealloc;
//   Module.digest  = mod.exports.digest;
//   Module.memory  = new Uint8Array(mod.exports.memory.buffer)

//   var input = document.getElementById("input");
//   var output = document.getElementById("output");
//   input.addEventListener("keyup", function(e) {
//     output.innerText = Sha1.digest(input.value);
//   });

//   alert(Sha1.digest("11"));
// });

// /////////////////////////////////////////////////////////
// const res = await fetch('chip8.wasm');
// const buffer = await res.arrayBuffer();
// const module = await WebAssembly.compile(buffer);
// const instance = await WebAssembly.instantiate(module);

// fetch('https://cofe-keanu.com/static/app/190321-213922-yuanmei-timer-elixir-iot/build/add.small.wasm')
//     .then(res => response.arrayBuffer())
//     .then(buffer => WebAssembly.compile(buffer))
//     .then(module => WebAssembly.instantiate(module))
//     .then(results => {
//         // alert(results.instance.exports.add_one(40));
//         // let s = results.instance.exports.string_from_rust();
//         alert(String("Just do it!"));
//         // alert(String(s));
// });

// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// [WORK!!!][james-ko][200127-153600]
// fetch('https://cofe-keanu.com/static/app/190321-213922-yuanmei-timer-elixir-iot/build/add.small.wasm')
//     .then(response => response.arrayBuffer())
//     .then(bytes => WebAssembly.instantiate(bytes, {}))
//     .then(results => {

//         const linearMemory = results.instance.exports.memory;
//         const offset = results.instance.exports.get_hello();
//         const stringBuffer = new Uint8Array(linearMemory.buffer, offset, results.instance.exports.get_hello_len());

//         let str = '';
//         for (let i=0; i<stringBuffer.length; i++) {
//             str += String.fromCharCode(stringBuffer[i]);
//         }
//         // alert(str); // <==========

//         // // alert(results.instance.exports.add_one(40));
//         // // let s = results.instance.exports.string_from_rust();
//         // alert(String("Just do it!"));
//         // // alert(String(s));
// });
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// // [WORK!!!][james-ko][200127-153600]
// fetch('https://cofe-keanu.com/static/app/190321-213922-yuanmei-timer-elixir-iot/build/add.small.wasm')
//     .then(response => response.arrayBuffer())
//     .then(bytes => WebAssembly.instantiate(bytes, {}))
//     .then(results => {

//         // alert(String("Just do it!"));

//         const linearMemory = results.instance.exports.memory;
//         const offset = results.instance.exports.get_str();
//         const stringBuffer = new Uint8Array(linearMemory.buffer, offset, results.instance.exports.get_str_len());

//         let str = '';
//         for (let i=0; i<stringBuffer.length; i++) {
//             str += String.fromCharCode(stringBuffer[i]);
//         }
//         // alert(str); //<============

//         // // alert(results.instance.exports.add_one(40));
//         // // let s = results.instance.exports.string_from_rust();
//         // // alert(String(s));
// });
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// // [WORK!!!][james-ko][200127-153600] [WASM => JS]
// window.Module = {};
// fetch('https://cofe-keanu.com/static/app/190321-213922-yuanmei-timer-elixir-iot/build/add.small.wasm')
//     .then(response => response.arrayBuffer())
//     .then(bytes => WebAssembly.instantiate(bytes, {}))
//     .then(results => {

//         Module.fact  = results.instance.exports.fact;
//         Module.alloc = results.instance.exports.alloc;
//         Module.dealloc_str = results.instance.exports.dealloc_str;
//         Module.memory = results.instance.exports.memory;

//         let outptr = results.instance.exports.fact_str(10);
//         let result = copyCStr(Module, outptr);
//         // alert(result);  // <=========
//         // alert(String("Just do it! 100"));

//         // //////////////////////////////////////////////////////////////////////////
//         // let elixir_websocket_180718_013452 = new Elixir_Websocket_180718_012548();
//         // elixir_websocket_180718_013452.init(result); 
//         // //////////////////////////////////////////////////////////////////////////

//         // const linearMemory = results.instance.exports.memory;
//         // const offset = results.instance.exports.get_str();
//         // const stringBuffer = new Uint8Array(linearMemory.buffer, offset, results.instance.exports.get_str_len());

//         // let str = '';
//         // for (let i=0; i<stringBuffer.length; i++) {
//         //     str += String.fromCharCode(stringBuffer[i]);
//         // }
//         // alert(str);

//         // // alert(results.instance.exports.add_one(40));
//         // // let s = results.instance.exports.string_from_rust();
//         // // alert(String(s));
// });
// /////////////////////////////////////////////////////////////////////
// // [WORK!!!][james-ko][200127-153600][JS => WASM]
// window.Module = {};
// let Sha1 = {
//   digest: str => {
//     let buf = newString(Module, str);
//     let outptr = Module.digest(buf);
//     let result = copyCStr(Module, outptr);
//     Module.dealloc_str(buf);
//     return result;
//     // alert(str);
//   }
// }
// fetch('https://cofe-keanu.com/static/app/190321-213922-yuanmei-timer-elixir-iot/build/add.small.wasm')
//     .then(response => response.arrayBuffer())
//     .then(bytes => WebAssembly.instantiate(bytes, {}))
//     .then(results => {

//         Module.alloc   = results.instance.exports.alloc;
//         Module.dealloc = results.instance.exports.dealloc;
//         Module.dealloc_str = results.instance.exports.dealloc_str;
//         Module.digest  = results.instance.exports.digest;
//         // Module.memory  = new Uint8Array(results.instance.exports.memory.buffer);
//         Module.memory = results.instance.exports.memory;

//         let s = Sha1.digest("s");
//         alert(s);  //<==== 
//         // alert(String("Just do it! 17"));

//         // Module.fact  = results.instance.exports.fact;
//         // Module.alloc = results.instance.exports.alloc;
//         // Module.dealloc_str = results.instance.exports.dealloc_str;
//         // Module.memory = results.instance.exports.memory;

//         // let outptr = results.instance.exports.fact_str(10);
//         // let result = copyCStr(Module, outptr);

//         // alert(result);
//         // alert(String("Just do it! 15"));

//         // const linearMemory = results.instance.exports.memory;
//         // const offset = results.instance.exports.get_str();
//         // const stringBuffer = new Uint8Array(linearMemory.buffer, offset, results.instance.exports.get_str_len());

//         // let str = '';
//         // for (let i=0; i<stringBuffer.length; i++) {
//         //     str += String.fromCharCode(stringBuffer[i]);
//         // }
//         // alert(str);

//         // // alert(results.instance.exports.add_one(40));
//         // // let s = results.instance.exports.string_from_rust();
//         // // alert(String(s));
// });
// /////////////////////////////////////////////////////////////////////
// // [Rust => JS]
// // ------------------
// window.Module = {};

// const wasm_to_js = (ptr) => {
//   let str = copyCStr(Module, ptr);
//   // alert(str);
// }

// const imports = {
//   env: {
//     wasm_to_js: wasm_to_js
//   }
// };

// fetchAndInstantiate("https://cofe-keanu.com/static/app/190321-213922-yuanmei-timer-elixir-iot/build/add.small.wasm", imports)
//     .then(mod => {
//       Module.memory      = mod.exports.memory;
//       Module.alloc       = mod.exports.alloc;
//       Module.dealloc     = mod.exports.dealloc;
//       Module.dealloc_str = () => {};
//       Module.js_to_wasm  = mod.exports.js_to_wasm;

//       Module.copyCStr    = copyCStr;
//       Module.getStr      = getStr;
//       Module.newString   = newString;
      
//       // Module.run         = mod.exports.run;
//       // Module.run();
//       let buf = Module.newString(Module, "3");
//       Module.js_to_wasm(buf);
//       Module.dealloc_str = results.instance.exports.dealloc_str;
//       Module.dealloc_str(buf);
// });
// function fetchAndInstantiate(url, importObject) {
//   return fetch(url).then(response => response.arrayBuffer())
//                    .then(bytes => WebAssembly.instantiate(bytes, importObject))
//                    .then(results => results.instance);
// }
// /////////////////////////////////////////////////////////////////////
// // [Rust => JS]
// window.Module = {};
// const test = (number) => {
//   alert(number);
// }
// const imports = {
//   env: {
//     test: test
//   }
// }
// fetchAndInstantiate("https://cofe-keanu.com/static/app/190321-213922-yuanmei-timer-elixir-iot/build/add.small.wasm", imports)
//     .then(mod => {
//       Module.memory      = mod.exports.memory;
//       Module.run         = mod.exports.run;
//       Module.run();
// });
// function fetchAndInstantiate(url, importObject) {
//   return fetch(url).then(response => response.arrayBuffer())
//                    .then(bytes => WebAssembly.instantiate(bytes, importObject))
//                    .then(results => results.instance);
// }
// /////////////////////////////////////////////////////////////////////
// // Copy a nul-terminated string from the buffer pointed to.
// // Consumes the old data and thus deallocated it.
// function copyCStr(module, ptr) {
//   let orig_ptr = ptr;
//   const collectCString = function* () {
//     let memory = new Uint8Array(module.memory.buffer);
//     while (memory[ptr] !== 0) {
//       if (memory[ptr] === undefined) { throw new Error("Tried to read undef mem") }
//       yield memory[ptr]
//       ptr += 1
//     }
//   }
//   const buffer_as_u8 = new Uint8Array(collectCString())
//   const utf8Decoder = new TextDecoder("UTF-8");
//   const buffer_as_utf8 = utf8Decoder.decode(buffer_as_u8);
//   module.dealloc_str(orig_ptr);
//   return buffer_as_utf8
// }
// /////////////////////////////////////////////////////////////////////
// function getStr(module, ptr, len) {
//   const getData = function* (ptr, len) {
//     let memory = new Uint8Array(module.memory.buffer);
//     for (let index = 0; index < len; index++) {
//       if (memory[ptr] === undefined) { throw new Error(`Tried to read undef mem at ${ptr}`) }
//       yield memory[ptr + index]
//     }
//   }
//   const buffer_as_u8 = new Uint8Array(getData(ptr/8, len/8));
//   const utf8Decoder = new TextDecoder("UTF-8");
//   const buffer_as_utf8 = utf8Decoder.decode(buffer_as_u8);
//   return buffer_as_utf8;
// }
// /////////////////////////////////////////////////////////////////////
// function newString(module, str) {
//   const utf8Encoder = new TextEncoder("UTF-8");
//   let string_buffer = utf8Encoder.encode(str);
//   let len = string_buffer.length;
//   let ptr = module.alloc(len+1);
//   let memory = new Uint8Array(module.memory.buffer);
//   for (let i = 0; i < len; i++) {
//     memory[ptr+i] = string_buffer[i]
//   }
//   memory[ptr+len] = 0;
//   return ptr;
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////

// // obtain the module memory
// const linearMemory = instance.exports.memory;

// // create a buffer starting at the reference to the exported string
// const offset = instance.exports.get_hello();
// const stringBuffer = new Uint8Array(linearMemory.buffer, offset,
//   instance.exports.get_hello_len());

// // create a string from this buffer
// let str = '';
// for (let i=0; i<stringBuffer.length; i++) {
//   str += String.fromCharCode(stringBuffer[i]);
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// // => WASM message
// let on_wasm_message = (msg) => {
//   alert(msg + "GYGYGYGY");
// }
// window.on_wasm_message = on_wasm_message;
// ///////////////////////////////////////////////////
// let wasm_200201_211400 = new WASM_200201_205400();
// wasm_200201_211400.init();
// /////////////////////////////////////////////////////////////////////////////////////////////////////////

// Elixir Websocket
let elixir_websocket_180718_013452 = new Elixir_Websocket_180718_012548();
// elixir_websocket_180718_013452.init('ws://cofe-keanu.com:8000/ws');

// elixir_websocket_180718_013452.init('wss://cofe-keanu.com:443/ws');        //<-- port 443 test
// elixir_websocket_180718_013452.init('wss://cofe-keanu.com:8443/ws');       //<-- current iot wss port
elixir_websocket_180718_013452.init(window.wss_uri_211121_115000);

// elixir_websocket_180718_013452.init('wss://cofe-keanu.com:8443/ws/chientan/ko/12345'); //<--
// elixir_websocket_180718_013452.init('wss://cofe-keanu.com:8443/ws/1/1/n/n/n/123'); //<--
// elixir_websocket_180718_013452.init('wss://cofe-keanu.com:8443/ws/2/1/01/02/00000022/123'); //<--
// elixir_websocket_180718_013452.init('wss://edengardentool.com:8443/ws');

//Elixir File Sender
let fsender_180718_060300 = new FSender_180718_053026();
fsender_180718_060300.init(elixir_websocket_180718_013452);
window.fsender_210807_043800 = fsender_180718_060300;

//Elixir File Receive Notifier
let file_receive_notifier_180722_005226 = new FReceive_Notifier_180725_073856();
file_receive_notifier_180722_005226.init(elixir_websocket_180718_013452);
window.file_receive_notifier_210814_112800 = file_receive_notifier_180722_005226;

// Elixir App Loader
let app_loader_180720_013720 = new App_Loader_180720_013150();
app_loader_180720_013720.init(elixir_websocket_180718_013452, fsender_180718_060300, file_receive_notifier_180722_005226);




