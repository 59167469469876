export default class File_Writer_180709_202054 {
    constructor() {
        // alert("File_Writer_180709_202054.constructor() >>>>");
        this.array_buffer = null;
        this.db = null;
        this.db_open = null;
        this.db_name = 'file-system';
        this.cc_of_overwrited_file = null;
        this.file_info_objectstore_name = "file-info";
        this.file_trunks_objectstore_name = "file-trunks";
        this.file_list = null;
        this.onwrite_db = null;
        this.onwrite_memory = null;
        this.path_with_basename = null;
        this.path_no_basename = null;
        this.payload = null;
        this.store = null;
        this.transaction = null;
        this.transaction_id = null;
        this.uuid_of_overwrited_file = null;
    }
    get_uuid(){
        let dt = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }
    write(path_no_basename, payload, write_to, file_list){
        this.path_no_basename = this.path_remove_tail_slash(path_no_basename);
        // alert("this.path_no_basename >>> " + this.path_no_basename);
        if(this.path_no_basename === "/"){
            this.path_with_basename = this.path_no_basename + payload.name;
        }else{
            this.path_with_basename = this.path_no_basename + "/" + payload.name;
        }
        this.payload = payload;
        ////////////////////One Folder One Database///////////////////
        // this.db_name = this.path_no_basename;
        //////////////////////////////////////////////////////////////
        // alert("payload.cf >>> " + payload.cf);
        if(payload.cf === "base64"){
            this.array_buffer = this.base64_to_arraybuffer(payload);
        }else if(payload.cf === "arraybuffer"){
            this.array_buffer = payload.chunk;
        }else{}
        // this.array_buffer = this.base64_to_arraybuffer(payload);
        this.file_list = file_list;
        // if(is_write_memory === true){
        //     this.write_to_file_list();
        // }
        // if(is_write_db === true){
        //     this.open_db_do_transaction("180531-040816");
        // }
        if(write_to === 'mem'){
            this.write_to_file_list();
        }else if(write_to === 'db'){
            this.open_db_do_transaction("180531-040816");
        }else if((write_to === 'mem_db')||(write_to === 'db_mem')){
            this.write_to_file_list();
            this.open_db_do_transaction("180531-040816");
        }else{}
    }
    path_remove_tail_slash(file_path){
        let path = '';
        if((file_path === "")||(file_path === null)){
            alert("[error][180529-191724] Invalid file path!");
            return null;
        }
        // alert("file_path.charCodeAt(0) >>> " + file_path.charCodeAt(0));
        if(file_path.charAt(0) !== '/'){
            file_path = '/' + file_path;
        }
        // alert("file_path >>> " + file_path);
        //------------------------------------------------------------
        // <h1>File Path No Basename | Method 1</h1>
        //------------------------------------------------------------
        // let last_slash_index = file_path.lastIndexOf("/");
        // // alert("last_slash_index >>> " + last_slash_index);
        // if(last_slash_index === 0){
        //     path_no_basename = '/';
        // }else{
        //     path_no_basename = file_path.slice(0, last_slash_index);
        // }
        // // alert("path_no_basename >>> " + path_no_basename);
        //------------------------------------------------------------
        // <h1>File Path No Basename | Method 2</h1>
        //------------------------------------------------------------
        if(file_path.length === 1){
            // alert('file_path.length === 1');
            path = '/';
        }else{
            if(file_path.charAt(file_path.length-1) !== '/'){
                path = file_path;
            }else{
                path = file_path.slice(0, file_path.length-1);
            }
        }
        // alert("path >>> " + path);
        return path;
    }
    base64_to_arraybuffer(payload){
        // alert('>>>>> convert_payload_to_arraybuffer(payload)');

        // base64 -> binary
        let binary = window.atob(payload.chunk);
        // alert('>>>>> binary.length : ' + binary.length);

        // binary -> ArrayBuffer
        let array_buffer = new ArrayBuffer(binary.length);
        let uint8array = new Uint8Array(array_buffer);
        for (let i = 0; i < binary.length; i++) {
            uint8array[i] = binary.charCodeAt(i);
        }

        // -----------------------------------------------------------------------
        // <h1>Verify the data is correct</h1>
        // ----------------------------------------------------------------------
        // // ArrayBuffer -> blob
        // let blob = new Blob([uint8array], {type: 'application/octet-stream'});
        // // var url = URL.createObjectURL(blob);
        
        // // // [blob test] blob -> FileReader
        // // let preview = document.querySelector('img');
        // // let reader  = new FileReader();
        // // reader.onload = () => {
        // //     preview.src = reader.result;
        // // };
        // // reader.readAsDataURL(blob);

        // // [blob test] blob -> file -> send to server
        // let fileSelected = new File([blob], payload.name);
        // this.file_receiver.file_uploader_manager.send_file_in_parallel(fileSelected);

        return array_buffer;
    }
    base64_to_blob(payload){
        // alert('>>>>> convert_payload_to_blob(payload)');

        // base64 -> binary
        let binary = window.atob(payload.chunk);
        // alert('>>>>> binary.length : ' + binary.length);

        // binary -> ArrayBuffer
        let array_buffer = new ArrayBuffer(binary.length);
        let uint8array = new Uint8Array(array_buffer);
        for (let i = 0; i < binary.length; i++) {
            uint8array[i] = binary.charCodeAt(i);
        }

        // ArrayBuffer -> blob
        let blob = new Blob([uint8array], {type: 'application/octet-stream'});
        // var url = URL.createObjectURL(blob);

        // -----------------------------------------------------------------------
        // <h1>Verify the data is correct</h1>
        // ----------------------------------------------------------------------
        // // [blob test] blob -> FileReader
        // let preview = document.querySelector('img');
        // let reader  = new FileReader();
        // reader.onload = () => {
        //     preview.src = reader.result;
        // };
        // reader.readAsDataURL(blob);

        // // [blob test] blob -> file -> send to server
        // let fileSelected = new File([blob], payload.name);
        // this.file_receiver.file_uploader_manager.send_file_in_parallel(fileSelected);

        return blob;
    }
    open_db_do_transaction(transaction_id){
        // alert("File_Writer_180530_140835.open_db_do_transaction(transaction_id) >>>>");
        if (!window.indexedDB) {
            alert("[error][180531-151422] Your browser doesn't support IndexedDB");
            return;
        }
        this.transaction_id = transaction_id;
        this.db_open = window.indexedDB.open(this.db_name);
        this.db_open.onupgradeneeded = (event) => {this.on_db_open_upgradeneeded(event)};
        this.db_open.onsuccess = (event) => {this.on_db_open_success(event)};
        this.db_open.onerror = (event) => {this.on_db_open_error(event)};
    }
    open_db_do_transaction(transaction_id, db_version){
        // alert("File_Writer_180530_140835.open_db_do_transaction(transaction_id, db_version) >>>>>>>>>>>>>> " + db_version);
        if (!window.indexedDB) {
            alert("[error][180601-003900] Your browser doesn't support IndexedDB");
            return;
        }
        if(db_version === undefined){
            // alert("db_version === undefined >>>>>>>>>>>>>> " + db_version);
            // this.open_db_do_transaction("180531-040816");
            // return;
            // alert("this.db_name >>>>>>>>>>>>>> " + this.db_name);
            this.transaction_id = transaction_id;
            this.db_open = window.indexedDB.open(this.db_name);
            this.db_open.onupgradeneeded = (event) => {this.on_db_open_upgradeneeded(event)};
            this.db_open.onsuccess = (event) => {this.on_db_open_success(event)};
            this.db_open.onerror = (event) => {this.on_db_open_error(event)};
        }else{
            this.transaction_id = transaction_id;
            this.db_open = window.indexedDB.open(this.db_name, db_version);
            this.db_open.onupgradeneeded = (event) => {this.on_db_open_upgradeneeded(event)};
            this.db_open.onsuccess = (event) => {this.on_db_open_success(event)};
            this.db_open.onerror = (event) => {this.on_db_open_error(event)};
        }
    }
    //[Conclusion] upgrade database 越少越好
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // An upgrade transaction enables the creation, renaming, and deletion of object stores and indexes in a database.
    // An upgrade transaction is exclusive. 
    // The steps to open a database ensure that only one connection to the database is open when an upgrade transaction is running. 
    // The upgradeneeded event isn’t fired, and thus the upgrade transaction isn’t started, until all other connections to the same database are closed. This ensures that all previous transactions are finished. 
    // As long as an upgrade transaction is running, attempts to open more connections to the same database are delayed, and any attempts to use the same connection to start additional transactions by calling transaction() will throw an exception. 
    // Thus upgrade transactions not only ensure that no other transactions are running concurrently, but also ensure that no new transactions are queued against the same database as long as the transaction is running.
    // This ensures that once an upgrade transaction is complete, the set of object stores and indexes in a database remain constant for the lifetime of all subsequent connections and transactions.
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    on_db_open_upgradeneeded(event){
        // alert("File_Writer_180530_140835.on_db_open_upgradeneeded(event) >>>>");
        this.db = this.db_open.result;
        // [Test][Create lots of object stores]
        /////////////////////////////////////////////////////////////////
        // for(let i=0; i<1000; i++){
        //     let store = this.db.createObjectStore(this.get_uuid());
        // }
        /////////////////////////////////////////////////////////////////
        if(this.db.objectStoreNames.contains(this.file_info_objectstore_name) === false){
            let store = this.db.createObjectStore(this.file_info_objectstore_name, {keyPath: "path"});
        }
        if(this.db.objectStoreNames.contains(this.file_trunks_objectstore_name) === false){
            let store = this.db.createObjectStore(this.file_trunks_objectstore_name);
        }
        // if(this.transaction_id === "180605-005347"){
        //     this.db.deleteObjectStore(this.uuid_of_overwrited_file);
        // }
    }
    on_db_open_success(event){
        // alert("File_Writer_180530_140835.on_db_open_success(event) >>>>");
        this.db = this.db_open.result;
        this.do_transaction(this.transaction_id, event);
    }
    on_db_open_error(event){
        alert('Fail to open ' + this.db_name + ' database');
    }
    close_db(){
        this.db.close();
        this.db = null;
        this.db_open = null;
    }
    do_transaction(transaction_id, event){
        this.transaction_id = transaction_id;        
        if(transaction_id === "180531-040816"){
            // alert('transaction_id === "180531-040816"');
            //[Conclusion] upgrade database 越少越好
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // An upgrade transaction enables the creation, renaming, and deletion of object stores and indexes in a database.
            // An upgrade transaction is exclusive. 
            // The steps to open a database ensure that only one connection to the database is open when an upgrade transaction is running. 
            // The upgradeneeded event isn’t fired, and thus the upgrade transaction isn’t started, until all other connections to the same database are closed. This ensures that all previous transactions are finished. 
            // As long as an upgrade transaction is running, attempts to open more connections to the same database are delayed, and any attempts to use the same connection to start additional transactions by calling transaction() will throw an exception. 
            // Thus upgrade transactions not only ensure that no other transactions are running concurrently, but also ensure that no new transactions are queued against the same database as long as the transaction is running.
            // This ensures that once an upgrade transaction is complete, the set of object stores and indexes in a database remain constant for the lifetime of all subsequent connections and transactions.
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            if(this.payload.cid === 1){
                if((this.db.objectStoreNames.contains(this.file_info_objectstore_name) === false)||(this.db.objectStoreNames.contains(this.file_trunks_objectstore_name) === false)){
                    // ----------------------------------------------------------------------
                    // <h1>Don't upgrade db, just quit</h1>
                    // ----------------------------------------------------------------------
                    this.db_version = this.db.version + 1;
                    this.close_db();
                    this.open_db_do_transaction("180612-115925", this.db_version);
                }else{
                    this.do_transaction("180612-115925", event);
                }
            }else{
                if((this.db.objectStoreNames.contains(this.file_info_objectstore_name) === false)||(this.db.objectStoreNames.contains(this.file_trunks_objectstore_name) === false)){
                    let transaction_result = null;
                    transaction_result = "180604-111955"; 
                    this.close_db();
                    this.onwrite_db({result: transaction_result, file_pusher_pid: this.payload.file_pusher_pid});
                }else{
                    this.do_transaction("180612-132108", event);
                }
            }
        }else if(transaction_id === "180612-115925"){
            // alert('transaction_id === "180612-115925"');
            let transaction_result = null;
            let transaction = null;
            let store_file_info = null;
            let store_file_chunks = null;
            let is_file_overwrited = false;

            transaction = this.db.transaction([this.file_info_objectstore_name, this.file_trunks_objectstore_name], "readwrite");
            store_file_info = transaction.objectStore(this.file_info_objectstore_name);
            store_file_chunks = transaction.objectStore(this.file_trunks_objectstore_name);

            let check_if_path_exists = store_file_info.count(this.path_with_basename);
            check_if_path_exists.onsuccess = (event) => {
                // alert('check_if_path_exists(event) >>>> ' + event.target.result);
                transaction_result = "000000-000000";
                if (event.target.result === 0) {
                    let put_file_info = null;
                    put_file_info = store_file_info.put({path: this.path_with_basename,
                                                         name: this.payload.name, 
                                                         dir: this.payload.dir, 
                                                         fs: this.payload.fs,
                                                         cc: this.payload.cc,
                                                         uuid: this.payload.uuid});
                    put_file_info.onsuccess = (event) => {
                        transaction_result = "000000-000000";
                        let put_chunk = store_file_chunks.put(this.array_buffer, this.payload.uuid + "-" + this.payload.cid);
                        put_chunk.onsuccess = (event) => {
                            // alert('put_chunk.onsuccess = (event)');
                            transaction_result = "000000-000000";
                        }
                        put_chunk.onerror = (event) => {
                            // alert('put_chunk.onerror = (event)');
                            transaction_result = "180601-045410";
                        }
                    }
                    put_file_info.onerror = (event) => {
                        transaction_result = "180605-004928";
                    }
                }else{
                    if(this.payload.wm === "ow"){
                        // alert('this.payload.wm === "ow" >>>>>>>>>>>>');
                        let get_overwrited_file_info = null;
                        let get_overwrited_file_info_result = "000000-000000";
                        get_overwrited_file_info = store_file_info.get(this.path_with_basename);
                        get_overwrited_file_info.onsuccess = (event) => {
                            // alert('event.target.result.uuid >>>>>>>>>>>>' + event.target.result.uuid);
                            transaction_result = "000000-000000";
                            if(event.target.result !== undefined){
                                this.uuid_of_overwrited_file = event.target.result.uuid;
                                this.cc_of_overwrited_file = event.target.result.cc;
                            }else{
                                get_overwrited_file_info_result = "180611-191939";
                            }
                            // alert('cc_of_overwrited_file >>>>>>>>>>>>' + this.cc_of_overwrited_file);
                            let put_file_info = store_file_info.put({path: this.path_with_basename,
                                                                     name: this.payload.name, 
                                                                     dir: this.payload.dir, 
                                                                     fs: this.payload.fs,
                                                                     cc: this.payload.cc,
                                                                     uuid: this.payload.uuid});
                            put_file_info.onsuccess = (event) => {
                                transaction_result = "000000-000000";
                                is_file_overwrited = true;
                                if(get_overwrited_file_info_result === "000000-000000"){
                                    for(let i = 0; i < this.cc_of_overwrited_file; i++){
                                        let delete_chunk = store_file_chunks.delete(this.uuid_of_overwrited_file + "-" + (i+1));
                                        delete_chunk.onsuccess = (event) => {
                                            // alert('delete_chunk.onsuccess ' + event.target.result);
                                        }
                                        delete_chunk.onerror = (event) => {
                                            // alert('delete_chunk.onerror ' + event.target.result);
                                            // transaction_result = "180609-061504"
                                        }
                                    }
                                }
                                let put_chunk = store_file_chunks.put(this.array_buffer, this.payload.uuid + "-" + this.payload.cid);
                                put_chunk.onsuccess = (event) => {
                                    // alert('put_chunk.onsuccess = (event)');
                                    transaction_result = "000000-000000";
                                }
                                put_chunk.onerror = (event) => {
                                    // alert('put_chunk.onerror = (event)');
                                    transaction_result = "180612-202238";
                                }
                            }
                            put_file_info.onerror = (event) => {
                                transaction_result = "180605-004004";
                            }
                        }
                        get_overwrited_file_info.onerror = (event) => {
                            // alert('get_file_info.onerror >>>>>>>>>>>>');
                            transaction_result = "180605-000610";
                        }
                    }else{
                        // alert('this.payload.wm === "now" >>>>>>>>>>>>');
                        let put_file_info = null;
                        put_file_info = store_file_info.put({path: this.path_with_basename + "-" + this.payload.uuid,
                                                             name: this.payload.name, 
                                                             dir: this.payload.dir, 
                                                             fs: this.payload.fs,
                                                             cc: this.payload.cc,
                                                             uuid: this.payload.uuid}); 
                        put_file_info.onsuccess = (event) => {
                            transaction_result = "000000-000000";
                            let put_chunk = store_file_chunks.put(this.array_buffer, this.payload.uuid + "-" + this.payload.cid);
                            put_chunk.onsuccess = (event) => {
                                // alert('put_chunk.onsuccess = (event)');
                                transaction_result = "000000-000000";
                            }
                            put_chunk.onerror = (event) => {
                                // alert('put_chunk.onerror = (event)');
                                transaction_result = "180612-203206";
                            }
                        }
                        put_file_info.onerror = (event) => {
                            transaction_result = "180605-004057";
                        }
                    }
                }                 
            }
            check_if_path_exists.onerror = (event) => {
                // alert('check_if_path_exists.onerror = (event)');
                transaction_result = "180604-231736";
            }
            transaction.oncomplete = (event) => {
                // alert('FUCK >>> this.transaction.oncomplete');
                // transaction_result = "180601-045410"; //<================================ Test ONLY !!!!
                this.close_db();
                this.onwrite_db({result: transaction_result, file_pusher_pid: this.payload.file_pusher_pid});
            }
        }else if(transaction_id === "180612-132108"){
            // alert('transaction_id === "180612-132108"');
            let transaction_result = null;
            let transaction = null;
            let store_file_info = null;
            let store_file_chunks = null;
            let get_file_info = null;

            transaction = this.db.transaction([this.file_info_objectstore_name, this.file_trunks_objectstore_name], "readwrite");
            store_file_info = transaction.objectStore(this.file_info_objectstore_name);
            store_file_chunks = transaction.objectStore(this.file_trunks_objectstore_name);

            get_file_info = store_file_info.get(this.path_with_basename);
            get_file_info.onsuccess = (event) => {
                // alert('get_file_info.onsuccess = (event)');
                transaction_result = "000000-000000";
                // alert("FUCK >> get_file_info.result.uuid: " + get_file_info.result.uuid);
                // alert("FUCK >> event.target.result.uuid: " + event.target.result.uuid);
                // this.file_trunks_objectstore_name = event.target.result.uuid;
                // alert("FUCK >> event.target.result: " + event.target.result);
                ////////////////////////////////////////////////////////////////
                // you may get nothing
                ////////////////////////////////////////////////////////////////
                if(event.target.result === undefined){
                    alert("FUCK >> event.target.result: " + event.target.result);
                    transaction_result = "180612-144334";
                }else{
                    if(this.payload.uuid === event.target.result.uuid){
                        let put_chunk = store_file_chunks.put(this.array_buffer, this.payload.uuid + "-" + this.payload.cid);
                        put_chunk.onsuccess = (event) => {
                            // alert('put_chunk.onsuccess = (event)');
                            transaction_result = "000000-000000";
                        }
                        put_chunk.onerror = (event) => {
                            // alert('put_chunk.onerror = (event)');
                            transaction_result = "180612-145414";
                        }
                    }else{
                        let get_overwrited_file_info = null;
                        get_overwrited_file_info = store_file_info.get(this.path_with_basename + "-" + this.payload.uuid);
                        get_overwrited_file_info.onsuccess = (event) => {
                            transaction_result = "000000-000000";
                            if(event.target.result !== undefined){
                                if(this.payload.uuid === event.target.result.uuid){
                                    let put_chunk = store_file_chunks.put(this.array_buffer, this.payload.uuid + "-" + this.payload.cid);
                                    put_chunk.onsuccess = (event) => {
                                        // alert('put_chunk.onsuccess = (event)');
                                        transaction_result = "000000-000000";
                                    }
                                    put_chunk.onerror = (event) => {
                                        // alert('put_chunk.onerror = (event)');
                                        transaction_result = "180612-210718";
                                    }
                                }else{
                                    transaction_result = "180612-210534";
                                }
                            }else{
                                transaction_result = "180611-191939";
                            }
                        }
                        get_overwrited_file_info.onerror = (event) => {
                            // alert('get_file_info.onerror >>>>>>>>>>>>');
                            transaction_result = "180612-205612";
                        }
                    }
                }
            }
            get_file_info.onerror = (event) => {
                // alert('get_file_info.onerror >>>> ' + event.target.result);
                transaction_result = "180601-152630"
            }

            transaction.oncomplete = (event) => {
                // alert('FUCK [2] >>> this.transaction.oncomplete');
                // transaction_result = "180612-145110";  //<================================ Test ONLY !!!!
                this.close_db();
                this.onwrite_db({result: transaction_result, file_pusher_pid: this.payload.file_pusher_pid});
            }
        }else{
            let transaction_result = null;
            transaction_result = "180604-144855";
            this.close_db();
            // this.file_receiver.on_file_write_transaction_done(this, transaction_result, this.payload.file_pusher_pid);
            this.onwrite_db({result: transaction_result, file_pusher_pid: this.payload.file_pusher_pid});
        }
    }
    write_to_file_list(){
        // alert('File_Writer_180612_232058.write_to_file_list()');
        let result ='000000-000000';
        let folder_list_length = this.file_list.length;
        // alert('folder_list_length >>>> ' + folder_list_length);
        let path_with_basename = this.path_with_basename;
        //////////////////////////////////////////////////////////////////////
        // if(this.payload.name !== "index.html"){
        //     alert('this.payload.cid >>>> ' + this.payload.cid);
        // }
        //////////////////////////////////////////////////////////////////////
        if(folder_list_length === 0){
            if(this.payload.cid === 1){
                this.file_list.push({folder: this.path_no_basename, file_list: [{path: path_with_basename, 
                                                                                name: this.payload.name,
                                                                                dir: this.payload.dir,
                                                                                cc: this.payload.cc,
                                                                                count_of_request_delete_after_read: 0,
                                                                                fs: this.payload.fs,
                                                                                uuid: this.payload.uuid,
                                                                                array_buffer: [this.array_buffer]}]});
                if(this.onwrite_memory !== null){
                    // alert('folder_list_length >>>> ' + this.file_list.length);
                    this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                }
                return; 
            }else{
                result = "180617-105234";
                // alert('result = "180617-105234" >>>> ');
                if(this.onwrite_memory !== null){
                    this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                }
                return; 
            }
        }else{
            for(let i=0; i<folder_list_length; i++){
                // alert('this.file_list[i].folder >>> ' + i + "---->" + this.file_list[i].folder);
                // alert('this.file_list[i].folder >>> ');
                if(this.path_no_basename === this.file_list[i].folder){
                    // alert('folder >>> ' + i);
                    let file_list_length = this.file_list[i].file_list.length;
                    // alert('file_list_length >>>>> ' + file_list_length);
                    // alert('this.file_list[i].file_list[file_list_length-1].path >>>>> ' + this.file_list[i].file_list[file_list_length-1].path);
                    //////////////////////////////////////////////////////////////////////
                    // if(this.payload.name !== "index.html"){
                    //     alert('this.path_no_basename === this.file_list[i].folder');
                    //     alert('file_list_length >>>>> ' + file_list_length);
                    // }
                    //////////////////////////////////////////////////////////////////////
                    if(file_list_length === 0){
                        if(this.payload.cid === 1){
                            // alert('FUCK >>>>>>>>>>> ' + file_list_length);
                            this.file_list[i].file_list.push({path: path_with_basename, 
                                                              name: this.payload.name,
                                                              dir: this.payload.dir,
                                                              cc: this.payload.cc,
                                                              count_of_request_delete_after_read: 0,
                                                              fs: this.payload.fs,
                                                              uuid: this.payload.uuid,
                                                              array_buffer: [this.array_buffer]});
                            if(this.onwrite_memory !== null){
                                // alert('folder_list_length >>>> ' + this.file_list.length);
                                this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                            }
                            return; 
                        }else{
                            // alert('result = 180616-130645 >>>>>>>> ' + path_with_basename);
                            // alert('this.payload.cid >>>>>>>> ' + this.payload.cid);
                            result ='180616-130645';
                            if(this.onwrite_memory !== null){
                                // alert('folder_list_length >>>> ' + this.file_list.length);
                                this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                            }
                            return; 
                        }                            
                    }else{
                        for(let y=0; y < file_list_length; y++){
                            // alert('this.file_list[i].file_list[0].path >>>>> ' + this.file_list[i].file_list[y].path);
                            // alert('this.file_list[i].file_list[y].cid >>>>> ' + this.file_list[i].file_list[y].cid);
                            if((this.path_with_basename + "-" + this.payload.uuid) === this.file_list[i].file_list[y].path){
                                this.file_list[i].file_list[y].array_buffer.push(this.array_buffer);
                                if(this.onwrite_memory !== null){
                                    // alert('folder_list_length >>>> ' + this.file_list.length);
                                    this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                                }
                                return;                                
                            }else if(path_with_basename === this.file_list[i].file_list[y].path){
                                if(this.payload.uuid === this.file_list[i].file_list[y].uuid){
                                    // alert('FUCK [3] >>>>> this.payload.cid:' + this.payload.cid);
                                    // alert('this.payload.uuid === this.file_list[i].file_list[y].uuid >>>>> ' + this.payload.uuid);
                                    // alert('this.payload.cid >>>>> ' + this.payload.cid);
                                    this.file_list[i].file_list[y].array_buffer.push(this.array_buffer);
                                    // alert('FUCK [3] >>>>> array_buffer.length:' + this.file_list[i].file_list[y].array_buffer.length);
                                    if(this.onwrite_memory !== null){
                                        // alert('folder_list_length >>>> ' + this.file_list.length);
                                        this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                                    }
                                    return; 
                                }else{
                                    if(this.payload.wm === "ow"){
                                        if(this.payload.cid === 1){
                                            //////////////////////////////////////////////////////////////////////
                                            // if(this.payload.name !== "index.html"){
                                            //     alert('this.payload.wm === "ow"');
                                            //     alert('this.payload.cid === 1 >>>>> ');
                                            // }
                                            //////////////////////////////////////////////////////////////////////
                                            this.file_list[i].file_list[y] = {path: path_with_basename, 
                                                                              name: this.payload.name,
                                                                              dir: this.payload.dir,
                                                                              cc: this.payload.cc,
                                                                              count_of_request_delete_after_read: 0,
                                                                              fs: this.payload.fs,
                                                                              uuid: this.payload.uuid,
                                                                              array_buffer: [this.array_buffer]}
                                            if(this.onwrite_memory !== null){
                                                // alert('folder_list_length >>>> ' + this.file_list.length);
                                                this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                                            }
                                            return; 
                                        }else{
                                            // alert('result = 180614-015752  >>>>>>>> ' + path_with_basename);
                                            // alert('result = 180614-015752  this.payload.uuid >>>>>>>> ' + this.payload.uuid);
                                            result ="180614-015752";
                                              if(this.onwrite_memory !== null){
                                                // alert('folder_list_length >>>> ' + this.file_list.length);
                                                this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                                            }
                                            return; 
                                        }                                
                                    }else{
                                        path_with_basename = this.path_with_basename + "-" + this.payload.uuid;
                                        // alert('non-overwrite >>>>>>>> ' + path_with_basename);
                                        // alert('file_list_length >>>>> ' + file_list_length);
                                        if(this.payload.cid === 1){
                                            if(y === (file_list_length-1)){
                                                if(this.payload.cid === 1){
                                                    this.file_list[i].file_list.push({path: path_with_basename, 
                                                                                      name: this.payload.name,
                                                                                      dir: this.payload.dir,
                                                                                      cc: this.payload.cc,
                                                                                      count_of_request_delete_after_read: 0,
                                                                                      fs: this.payload.fs,
                                                                                      uuid: this.payload.uuid,
                                                                                      array_buffer: [this.array_buffer]});
                                                    if(this.onwrite_memory !== null){
                                                        // alert('folder_list_length >>>> ' + this.file_list.length);
                                                        this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                                                    }
                                                    return; 
                                                }else{
                                                    result = "180617-111058";
                                                    // alert('result  >>>>>>> ' + result);
                                                    if(this.onwrite_memory !== null){
                                                        this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                                                    }
                                                    return;
                                                }
                                            }
                                        }else{ }
                                    }                                
                                }
                            }else if(path_with_basename < this.file_list[i].file_list[y].path){
                                // alert('FUCK [0] >>>>> this.payload.cid:' + this.payload.cid);
                                if(this.payload.cid === 1){
                                    this.file_list[i].file_list.splice(y, 0, {path: path_with_basename, 
                                                                              name: this.payload.name,
                                                                              dir: this.payload.dir,
                                                                              cc: this.payload.cc,
                                                                              count_of_request_delete_after_read: 0,
                                                                              fs: this.payload.fs,
                                                                              uuid: this.payload.uuid,
                                                                              array_buffer: [this.array_buffer]});
                                    // alert('FUCK [0] >>>>> array_buffer.length:' + this.file_list[i].file_list[y].array_buffer.length);
                                    if(this.onwrite_memory !== null){
                                        // alert('folder_list_length >>>> ' + this.file_list.length);
                                        this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                                    }
                                    return; 
                                }else{
                                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                    // result = "180617-110226";
                                    // alert('result  >>>>>>> ' + result);
                                    if(this.onwrite_memory !== null){
                                        this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                                    }
                                    return;  
                                    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////                                                        
                                }
                            }else{
                                if(y === (file_list_length-1)){
                                    // alert('FUCK [1] >>>>> this.payload.cid:' + this.payload.cid);
                                    if(this.payload.cid === 1){
                                        this.file_list[i].file_list.push({path: path_with_basename, 
                                                                          name: this.payload.name,
                                                                          dir: this.payload.dir,
                                                                          cc: this.payload.cc,
                                                                          count_of_request_delete_after_read: 0,
                                                                          fs: this.payload.fs,
                                                                          uuid: this.payload.uuid,
                                                                          array_buffer: [this.array_buffer]});
                                        if(this.onwrite_memory !== null){
                                            // alert('folder_list_length >>>> ' + this.file_list.length);
                                            this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                                        }
                                        return; 
                                    }else{
                                        result = "180617-110358";
                                        // alert('result  >>>>>>> ' + result);
                                        if(this.onwrite_memory !== null){
                                            this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                                        }
                                        return;  
                                    }
                                }
                            }
                        }
                    }
                }else if(this.path_no_basename < this.file_list[i].folder){
                    // alert('this.path_no_basename < this.file_list[i].folder');
                    if(this.payload.cid === 1){
                        this.file_list.splice(i, 0, {folder: this.path_no_basename, file_list: [{path: path_with_basename, 
                                                                                                name: this.payload.name,
                                                                                                dir: this.payload.dir,
                                                                                                cc: this.payload.cc,
                                                                                                count_of_request_delete_after_read: 0,
                                                                                                fs: this.payload.fs,
                                                                                                uuid: this.payload.uuid,
                                                                                                array_buffer: [this.array_buffer]}]})
                        // alert('this.file_list[i].folder >>>> ' + this.file_list[i].folder);
                        // alert('this.file_list[i+1].folder >>>> ' + this.file_list[i+1].folder);
                        if(this.onwrite_memory !== null){
                            // alert('folder_list_length >>>> ' + this.file_list.length);
                            this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                        }
                        return; 
                    }else{
                        // result = "180617-110640";
                        // alert('result  >>>>>>> ' + result);
                        if(this.onwrite_memory !== null){
                            this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                        }
                        return;  
                    }
                }else{
                    // alert('this.path_no_basename > this.file_list[i].folder');
                    if(i === (folder_list_length-1)){
                        // alert('FUCK >>>>>>>>>> ' + this.file_list.length);
                        if(this.payload.cid === 1){
                            this.file_list.push({folder: this.path_no_basename, file_list: [{path: path_with_basename, 
                                                                                            name: this.payload.name,
                                                                                            dir: this.payload.dir,
                                                                                            cc: this.payload.cc,
                                                                                            count_of_request_delete_after_read: 0,
                                                                                            fs: this.payload.fs,
                                                                                            uuid: this.payload.uuid,
                                                                                            array_buffer: [this.array_buffer]}]});
                            // alert('FUCK >>>>>>>>>> ' + this.file_list.length);
                            // alert('path_with_basename >>>>>>>> ' + path_with_basename);
                            if(this.onwrite_memory !== null){
                                // alert('folder_list_length >>>> ' + this.file_list.length);
                                this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                            }
                            return; 
                        }else{
                            result = "180617-110750";
                            // alert('result  >>>>>>> ' + result);
                            if(this.onwrite_memory !== null){
                                this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
                            }
                            return;  
                        }
                    }
                }
            }
        }
        if(this.onwrite_memory !== null){
            // alert('folder_list_length >>>> ' + this.file_list.length);
            this.onwrite_memory({result: result, sas: path_with_basename, file_pusher_pid: this.payload.file_pusher_pid});
        }
    }
}