import File_Reader_180709_202430 from "../websocket/freceiver/file_reader_180709_202430"

export default class App_Loader_180720_013150 {
    constructor() {
        // alert("App_Loader_180720_013150.constructor() >>>>");
        // /home/james/Desktop/binary-data/hex-test-4-index-lite-坑的健康快樂的飛機離開 { шеллы=汉字简体繁体转换-Günter-조선글? : @ & = + $ # }
        this.proj_path = window.proj_path_211122_175600;
        this.script_path = window.js_script_path_211122_175601;
        this.three_lib_path = window.three_lib_path_211122_175602;
        this.elixir_websocket = null;
        this.fsender = null;
        this.file_receive_notifier = null;
        this.chatInput = null;
        this.h1_181220_011322 = null;
        this.app_180711_232522 = null;
        this.app_180712_102226 = null;
        // this.path_file_0000 = this.proj_path + '/html/static/app/web/index.html';  // NOT WORK --> flutter app
        this.path_file_0000 = this.proj_path + '/html/app-180711-232522.html';  //  /var/www/html/static/js/es6-cofe-keanu-client/html/app-180711-232522.html  /home/james/Desktop/binary-data/index.html
        this.path_file_0001 = this.proj_path + '/html/app-180711-230000-d3.html';
        this.path_file_0002 = '~/Desktop/binary-data/temp3.zip';
        this.path_file_0003 = '~/Desktop/binary-data/ubuntu-16.04.4-desktop-amd64.iso';
        this.path_file_210814_113200 = '/var/www/html/public/upload/f_U_1.webm';
        this.onreceive_file_in_mem_file_0000 = (event) => {this.on_receive_file_in_mem_file_0000(event)};
        this.onreceive_file_in_mem_file_0001 = (event) => {this.on_receive_file_in_mem_file_0001(event)};
        this.onreceive_file_in_mem_file_0002 = (event) => {this.on_receive_file_in_mem_file_0002(event)};
        this.onreceive_file_in_mem_file_0003 = (event) => {this.on_receive_file_in_mem_file_0003(event)};
        this.onreceive_file_in_mem_file_210814_113200 = (event) => {this.on_receive_file_in_mem_file_210814_113200(event)};
        this.wait_elixir_channel = null;
        this.elixir_ws_max_wait = 10*1000;
        this.elixir_ws_total_wait = 0;
        this.grid_190202_162410 = null;
        this.grid_element_190210_012930 = null;
        this.p_190202_201350 = null;
        this.resizeCount = 0;
        this.yuanmei_timer_iot_msg_test_190403_082808 = null;
        this.yuanmei_timer_iot_msg_info_190404_223536 = null;
        this.yuanmei_timer_iot_msg_output_190404_222800 = null;
        this.yuanmei_timer_iot_msg_input_190403_104732 = null;
        this.yuanmei_iot_button_190403_163422 = null;
        this.st_connection = false;
    }
    init(elixir_websocket, fsender, file_receive_notifier){
        // alert("App_Loader_180720_013150.init(elixir_websocket, fsender, file_receive_notifier) >>>>");
        this.elixir_websocket = elixir_websocket;
        this.elixir_websocket.ws.addEventListener('open', event => {this.on_elixir_open(event)}); //callback was added to list
        this.elixir_websocket.ws.addEventListener('message', event => {this.on_elixir_message(event)}); //callback was added to list
        this.elixir_websocket.ws.addEventListener('close', event => {this.on_elixir_close(event)}); //callback was added to list
        this.fsender = fsender;
        this.file_receive_notifier = file_receive_notifier;
        this.file_receive_notifier.register({path: this.path_file_0000, callback: this.onreceive_file_in_mem_file_0000, request_delete_after_read: true}, 'file_in_mem');
        this.file_receive_notifier.register({path: this.path_file_0001, callback: this.onreceive_file_in_mem_file_0001, request_delete_after_read: true}, 'file_in_mem');
        this.file_receive_notifier.register({path: this.path_file_0002, callback: this.onreceive_file_in_mem_file_0002, request_delete_after_read: true}, 'file_in_mem');
        this.file_receive_notifier.register({path: this.path_file_0003, callback: this.onreceive_file_in_mem_file_0003, request_delete_after_read: true}, 'file_in_mem');
        this.h1_181220_011322 = document.querySelector("#h2-181220-011322");
        this.app_180711_232522 = document.querySelector("#app-180711-232522");
        this.app_180712_102226 = document.querySelector("#app-180712-102226");
        this.chatInput = document.querySelector("#channel-input-180718-005010");
        this.chatInput.addEventListener('keypress', event => {this.on_chatInput_keypress(event)}); //callback was added to list
        // window.onload = () => {this.on_page_loaded()}; // ==> (it'll cause chrome not work! Don't use it) all external resources are all loaded
        document.addEventListener("DOMContentLoaded", event => {this.on_dom_loaded(event)}); // ==> DOM is ready

        this.grid_190202_162410 = document.querySelector(".grid-container-190202-162410");
        this.grid_element_190210_012930 = document.querySelector(".grid-190210-012930");
        this.p_190202_201350 = document.querySelector("#p-190202-201350");
        window.addEventListener("resize", () => {this.on_grid_190202_162410_resize()});
        this.p_190202_201350.innerHTML = [
            "grid_190202_162410",
            "resizeCount: " + this.resizeCount,
            "grid_190202_162410.clientWidth: " + this.grid_190202_162410.clientWidth,
            "grid_190202_162410.clientHeight: " + this.grid_190202_162410.clientHeight,
            "grid_190202_162410.offsetWidth: " + this.grid_190202_162410.offsetWidth,
            "grid_190202_162410.offsetHeight: " + this.grid_190202_162410.offsetHeight,
            "grid_element_190210_012930",
            "grid_element_190210_012930.clientWidth: " + this.grid_element_190210_012930.clientWidth,
            "grid_element_190210_012930.clientHeight: " + this.grid_element_190210_012930.clientHeight,
            "grid_element_190210_012930.offsetWidth: " + this.grid_element_190210_012930.offsetWidth,
            "grid_element_190210_012930.offsetHeight: " + this.grid_element_190210_012930.offsetHeight,
            "document.documentElement.clientWidth: " + document.documentElement.clientWidth,
            "document.documentElement.clientHeight: " + document.documentElement.clientHeight,
            "document.documentElement.offsetWidth: " + document.documentElement.offsetWidth,
            "document.documentElement.offsetHeight: " + document.documentElement.offsetHeight,
            "document.documentElement.scrollWidth: " + document.documentElement.scrollWidth,
            "document.documentElement.scrollHeight: " + document.documentElement.scrollHeight,
            "document.body.clientWidth: " + document.body.clientWidth,
            "document.body.clientHeight: " + document.body.clientHeight,
            "document.body.offsetWidth: " + document.body.offsetWidth,
            "document.body.offsetHeight: " + document.body.offsetHeight,
            "document.body.scrollWidth: " + document.body.scrollWidth,
            "document.body.scrollHeight: " + document.body.scrollHeight,
            "window.innerWidth: " + window.innerWidth,
            "window.innerHeight: " + window.innerHeight,
            "window.outerWidth: " + window.outerWidth,
            "window.outerHeight: " + window.outerHeight,
            "window.screen.width: " + window.screen.width,
            "window.screen.height: " + window.screen.height,
            "window.screen.availWidth: " + window.screen.availWidth,
            "window.screen.availHeight: " + window.screen.availHeight
        ].join("<br>");

        window.grid_190202_162410_clientWidth = this.grid_190202_162410.clientWidth;
        window.grid_190202_162410_clientHeight = this.grid_190202_162410.clientHeight;

        this.yuanmei_timer_iot_msg_test_190403_082808 = document.querySelector(".yuanmei-timer-iot-msg-test-190403-082808");
        this.yuanmei_timer_iot_msg_test_190403_082808.style.height = this.grid_190202_162410.clientHeight;
        this.yuanmei_timer_iot_msg_info_190404_223536 = document.querySelector("#yuanmei-timer-iot-msg-info-190404-223536");
        this.update_info();
        
        this.yuanmei_timer_iot_msg_output_190404_222800 = document.querySelector("#yuanmei-timer-iot-msg-output-190404-222800");
        this.yuanmei_timer_iot_msg_input_190403_104732 = document.querySelector("#yuanmei-timer-iot-msg-input-190403-104732");
        // this.yuanmei_timer_iot_msg_input_190403_104732.addEventListener('keypress', event => {this.on_yuanmei_timer_iot_msg_input_190403_104732_keypress(event)}); //callback was added to list
        this.yuanmei_iot_button_190403_163422 = document.querySelector("#yuanmei-iot-button-190403-163422");
        // [NOT work] set button size
        // this.yuanmei_iot_button_190403_163422.style.height = this.yuanmei_timer_iot_msg_test_190403_082808.style.height * 0.1;
        this.yuanmei_iot_button_190403_163422.addEventListener('click', () => {this.on_yuanmei_iot_button_190403_163422_click()}); //callback was added to list
    }
    update_info(){
        if(this.st_connection === true){
            this.yuanmei_timer_iot_msg_info_190404_223536.value = ('connection status: '+ this.st_connection + ' ========> (O)' + '\n\n');
        }else{
            this.yuanmei_timer_iot_msg_info_190404_223536.value = ('connection status: '+ this.st_connection + ' ========> (X)' + '\n\n');
        }
    }
    isJson(str){
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    on_yuanmei_iot_button_190403_163422_click(){
        // alert("App_Loader_180720_013150.on_yuanmei_iot_button_190403_163422_click() >>>>");
        let msg = this.yuanmei_timer_iot_msg_input_190403_104732.value;
        this.yuanmei_timer_iot_msg_input_190403_104732.value = ''; 
        if(this.elixir_websocket.channel !== null){
            if((msg != null)&&(msg != '')){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('\n--> '+msg+'\n');
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('====================================\n');
                if(this.isJson(msg)){
                    this.elixir_websocket.channel.send(msg); 
                }else{
                    // this.yuanmei_timer_iot_msg_input_190403_104732.value = 'Invalid Json string!\n'; 
                    this.yuanmei_timer_iot_msg_output_190404_222800.value += 'Invalid Json string!\n\n'; 
                    // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');               
                }
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += '------>\n';
                this.yuanmei_timer_iot_msg_output_190404_222800.scrollTop = this.yuanmei_timer_iot_msg_output_190404_222800.scrollHeight;
            }
        } 
    }
    // on_yuanmei_timer_iot_msg_input_190403_104732_keypress(event){
    //     if(event.keyCode === 13){
    //         // alert("App_Loader_180720_013150.on_yuanmei_timer_iot_msg_input_190403_104732_keypress(event) >>>>");
    //         let msg = this.yuanmei_timer_iot_msg_input_190403_104732.value;
    //         // this.yuanmei_timer_iot_msg_input_190403_104732.value = null; 
    //         this.yuanmei_timer_iot_msg_input_190403_104732.value = ''; 
    //         // this.yuanmei_timer_iot_msg_input_190403_104732.setSelectionRange(0, 1);
    //         // this.yuanmei_timer_iot_msg_input_190403_104732.focus();
    //         // this.yuanmei_timer_iot_msg_input_190403_104732.setSelectionRange(0, 1);
    //         // this.yuanmei_timer_iot_msg_input_190403_104732.createTextRange();
    //         // this.yuanmei_timer_iot_msg_input_190403_104732.innerHTML = '';
    //         // document.getElementById('tag_txt1').innerHTML = ""; 
    //         if(this.elixir_websocket.channel !== null){
    //             if((msg != null)&&(msg != '')){
    //                 this.elixir_websocket.channel.send(msg); 
    //             }
    //         }         
    //     }
    // }
    on_grid_190202_162410_resize(){
        this.resizeCount += 1;
        this.p_190202_201350.innerHTML = [
            "grid_190202_162410",
            "resizeCount: " + this.resizeCount,
            "grid_190202_162410.clientWidth: " + this.grid_190202_162410.clientWidth,
            "grid_190202_162410.clientHeight: " + this.grid_190202_162410.clientHeight,
            "grid_190202_162410.offsetWidth: " + this.grid_190202_162410.offsetWidth,
            "grid_190202_162410.offsetHeight: " + this.grid_190202_162410.offsetHeight,
            "grid_element_190210_012930",
            "grid_element_190210_012930.clientWidth: " + this.grid_element_190210_012930.clientWidth,
            "grid_element_190210_012930.clientHeight: " + this.grid_element_190210_012930.clientHeight,
            "grid_element_190210_012930.offsetWidth: " + this.grid_element_190210_012930.offsetWidth,
            "grid_element_190210_012930.offsetHeight: " + this.grid_element_190210_012930.offsetHeight,
            "document.documentElement.clientWidth: " + document.documentElement.clientWidth,
            "document.documentElement.clientHeight: " + document.documentElement.clientHeight,
            "document.documentElement.offsetWidth: " + document.documentElement.offsetWidth,
            "document.documentElement.offsetHeight: " + document.documentElement.offsetHeight,
            "document.documentElement.scrollWidth: " + document.documentElement.scrollWidth,
            "document.documentElement.scrollHeight: " + document.documentElement.scrollHeight,
            "document.body.clientWidth: " + document.body.clientWidth,
            "document.body.clientHeight: " + document.body.clientHeight,
            "document.body.offsetWidth: " + document.body.offsetWidth,
            "document.body.offsetHeight: " + document.body.offsetHeight,
            "document.body.scrollWidth: " + document.body.scrollWidth,
            "document.body.scrollHeight: " + document.body.scrollHeight,
            "window.innerWidth: " + window.innerWidth,
            "window.innerHeight: " + window.innerHeight,
            "window.outerWidth: " + window.outerWidth,
            "window.outerHeight: " + window.outerHeight,
            "window.screen.width: " + window.screen.width,
            "window.screen.height: " + window.screen.height,
            "window.screen.availWidth: " + window.screen.availWidth,
            "window.screen.availHeight: " + window.screen.availHeight
        ].join("<br>");

        window.grid_190202_162410_clientWidth = this.grid_190202_162410.clientWidth;
        window.grid_190202_162410_clientHeight = this.grid_190202_162410.clientHeight;
        this.yuanmei_timer_iot_msg_test_190403_082808.style.height = this.grid_190202_162410.clientHeight;
        // [NOT work] set button size
        // this.yuanmei_iot_button_190403_163422.style.height = this.yuanmei_timer_iot_msg_test_190403_082808.style.height * 0.1;
        this.yuanmei_timer_iot_msg_output_190404_222800.scrollTop = this.yuanmei_timer_iot_msg_output_190404_222800.scrollHeight;

        // // => [WASM][TEST] ///////////////////////////////////////////
        // let buf = Module.newString(Module, "3");
        // Module.js_to_wasm(buf);
        // // Module.dealloc_str = results.instance.exports.dealloc_str;
        // Module.dealloc_str(buf);
        // ///////////////////////////////////////////////////////////////
    }
    on_elixir_open(event){
        // alert('on_elixir_close >>>>>>>>>>>>>> ' + event);
        // alert('on_elixir_open >>>>>>>>>>>>>> ');
        this.st_connection = true;
        this.update_info();
    }
    on_elixir_message(event){
        // alert('on_elixir_message >>>>>>>>>>>>>> ' + event.data);
        if(typeof(event.data) === 'string'){
            let msg = JSON.parse(event.data);
            if(msg.mid === "181219-202634"){
                // alert("msg.mid === \"181219-202634\" >>>>");
                //setTimeout( ( ) => { alert("msg.mid === \"181219-202634\" >>>>"); }, 1000 );
                // this.chatInput.value = `[${Date()}]`;
                this.h1_181220_011322.innerHTML = `[${Date()}]`;
            }
            else if(msg.mid === "err"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "template_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "template_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "no_login_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_add_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_add_m_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "add_p_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_err_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_template_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_template_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_register_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_register_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_register_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_login_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_login_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_login_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_login_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_login_again_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_link_a_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_sh_m_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_sh_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_sh_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_sh_m_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_sh_m_5_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_accept_sh_m_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_accept_sh_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_accept_sh_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_refusel_sh_m_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_refusel_sh_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_refusel_sh_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_stop_sh_m_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_stop_sh_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_stop_sh_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_sh_c_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_sh_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_sh_c_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_sh_c_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_sh_c_5_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_accept_sh_c_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_accept_sh_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_accept_sh_c_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_refusel_sh_c_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_refusel_sh_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_refusel_sh_c_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_stop_sh_c_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_stop_sh_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_stop_sh_c_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_return_wr_p_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_p_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_p_pa_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_p_pa_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_p_pa_p_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_sa_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "wr_m_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "wr_c_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "wr_a_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_sp_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "wr_p_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_sm_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_sc_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_m_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_return_wr_c_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_return_s_scenes_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_c_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_a_pa_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_a_pa_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_a_pa_p_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_scenes_manual_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_scenes_any_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_scenes_and_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_scenes_exe_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_key_err_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_send_c_notify_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_update_m_weather_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_update_m_weather_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_return_s_scenes_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_return_wr_m_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_wr_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_wr_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_m_pa_pm_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_m_pa_pm_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_m_pa_pm_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_m_pa_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_m_pa_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_m_pa_p_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_c_pa_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_c_pa_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_c_pa_p_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_c_pa_pc_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_c_pa_pc_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_wr_c_pa_pc_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_sa_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_sp_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_update_p_weather_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_update_p_weather_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_update_m_weather_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_update_m_weather_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_sm_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_sc_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_p_pa_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_p_pa_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_p_pa_p_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_a_pa_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_a_pa_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_a_pa_p_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_m_pa_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_m_pa_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_m_pa_p_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_c_pa_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_c_pa_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_re_c_pa_p_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_write_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "out_p_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "del_p_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_link_update_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_link_update_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_link_a_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_online_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_online_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "pmc_online_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_out_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_out_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_out_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_del_badge_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "del_p_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_template_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_template_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_register_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_register_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_register_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_err_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_add_link_a_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_add_link_p_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_link_a_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_out_link_a_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_link_p_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_link_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_link_m_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_login_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_login_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_login_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_login_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_offonline_a_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_wr_m_pa_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_wr_m_pa_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_wr_m_pa_p_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_wr_c_pa_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_wr_c_pa_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_wr_c_pa_p_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_re_m_pa_m_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_re_m_pa_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_re_m_pa_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_re_c_pa_m_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_re_c_pa_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_re_c_pa_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_re_sm_time_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_news_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_news_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_add_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_return_add_c_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_add_c_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "out_m_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "del_m_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "administrator_err_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "administrator_wr_data_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "add_m_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "add_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "add_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_link_update_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_link_update_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_link_a_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_link_a_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "mc_online_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "mc_online_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_online_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_online_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_online_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_link_c_acct_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_return_ota_m_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_ota_end_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "m_ota_end_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "online_m_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "online_c_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_template_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_template_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_register_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_register_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_register_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_add_link_a_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_login_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_login_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_login_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_login_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_offonline_a_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_offonline_a_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_offonline_a_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_wr_c_pa_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_wr_c_pa_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_wr_c_pa_p_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_re_c_pa_c_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_re_c_pa_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_re_c_pa_c_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_test_online_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_online_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_online_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_offline_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "c_online_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "online_c_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "out_c_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "del_c_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "add_c_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "add_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "add_c_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_update_a_acct_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_update_a_acct_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_template_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_template_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_register_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_register_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_register_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_register_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_verification_a_acct_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_verification_a_acct_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_verification_a_acct_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_verification_a_acct_5_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_verification_a_acct_6_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_verification_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_verification_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_verification_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_verification_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_verification_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_verification_5_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_login_1_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_login_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_login_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_login_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_login_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_link_acct_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_share_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_share_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_share_m_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_share_m_5_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_accept_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_accept_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_refusel_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_refusel_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_stop_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_stop_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_online_mc_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_online_mc_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_online_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_online_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_online_mc_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "p_ota_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_out_p_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_out_p_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_out_m_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_out_m_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_out_m_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_out_m_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_out_c_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_out_c_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_out_c_3_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "a_out_c_4_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "test_url_1_0_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "test_url_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "test_c_login_2_v1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "test_yuanmei_timer_response"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "test_api_response"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "fcm_test_done"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "log"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            else if(msg.mid === "13_1"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += (`[${Date()}]` + '\n');
            }
            else if(msg.mid === "13_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n');
            }
            else if(msg.mid === "13_3"){
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += (`[${Date()}]` + '\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += (msg.err_msg_1_9 + '\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ( '{"mid":"' + msg.mid + '","1_9":' + msg["1_9"] + '\n');
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n');
            }
            else if(msg.mid === "1_1_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "1_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "1_3"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "1_4"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "2_1_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "2_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "2_3"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "2_4"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "3_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "4_1_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "4_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "4_3"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "5_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "6_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "7_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "7_3"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "9_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "9_3"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "10_1_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "10_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "10_3"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "11_1_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "11_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "11_3"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "11_4"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "11_5"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "12_1_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "12_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "13_1_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "13_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "13_3"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "13_4"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "14_1_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "14_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "15_1_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "15_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "15_3"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "19_1_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "19_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "20_1_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "20_2"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "20_3"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
            else if(msg.mid === "0_0"){
                this.yuanmei_timer_iot_msg_output_190404_222800.value += ('<-- ' + event.data + '\n\n');
                // this.yuanmei_timer_iot_msg_output_190404_222800.value += ('------------------------------------\n');
            }
        }
    }
    on_elixir_close(event){
        // alert('on_elixir_close >>>>>>>>>>>>>> ' + event);
        // alert('on_elixir_close >>>>>>>>>>>>>> ');
        this.st_connection = false;
        this.update_info();
    }
    get_uuid(){
        let dt = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }
    on_receive_file_in_mem_file_0000(event){
        // alert("App_Loader_180720_013150.on_receive_file_in_mem_file_0003(event) >>>>");
        let file_reader = new File_Reader_180709_202430();
        file_reader.onread_mem = (event) => {
            if(file_reader.file !== null){
                // ----------------------------------------------
                // Verify the file is correct
                // ----------------------------------------------
                // if(this.elixir_websocket.channel !== null){
                //     this.fsender.send_file_in_parallel(file_reader.file);
                // }
                // ----------------------------------------------
                // Load APP
                // ----------------------------------------------
                let fileReader = new FileReader();
                fileReader.onload = (event) => {
                    //////////////////////////////////////////////////////////
                    // [Do this will cause DOM events unresponsive]
                    //////////////////////////////////////////////////////////
                    // alert('fileReader.onload >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');   
                    // this.messagesContainer.innerHTML += ('<prep>' + fileReader.result + '</prep>');
                    // this.messagesContainer.innerHTML += ('<pre>' + fileReader.result + '</pre>');
                    // this.messagesContainer.innerHTML += ('<pre>' + " FFFFFFFFFFFFFFFFFFFF FFFFFFFF  " + '</pre>');
                    // this.messagesContainer.innerHTML += ('<iframe>' + fileReader.result + '</iframe>');

                    //////////////////////////////////////////////////////////
                    // This is OK!
                    //////////////////////////////////////////////////////////
                    // let messageItem = document.createElement("li");
                    // messageItem.innerHTML = ('<prep>' + fileReader.result + '</prep>');
                    // this.messagesContainer.appendChild(messageItem);

                    ///////////////////////////////////////////////////window.app_loader_190212_014034
                    // This is OK!
                    ///////////////////////////////////////////////////window.app_loader_190212_014034
                    // let messageItem = document.createElement("div");window.app_loader_190212_014034
                    // messageItem.innerHTML = ('<prep>' + fileReader.result + '</prep>');
                    // this.app_180711_232522.appendChild(messageItem);
                    this.app_180711_232522.innerHTML = ('<prep>' + fileReader.result + '</prep>');

                    let newScript0 = document.createElement("script");
                    newScript0.src = this.script_path + "/js/aframe/aframe.min.js";
                    // newScript0.src = "https://aframe.io/releases/1.4.0/aframe.min.js"; // --> test it
                    newScript0.async = false;
                    this.app_180711_232522.appendChild(newScript0);

                    let newScript1 = document.createElement("script");
                    newScript1.src = this.script_path + "/js/webgl/framework/babylon/lib/babylon.custom.js";
                    newScript1.async = false;
                    this.app_180711_232522.appendChild(newScript1);

                    let newScript2 = document.createElement("script");
                    newScript2.src = this.three_lib_path;
                    newScript2.async = false;
                    this.app_180711_232522.appendChild(newScript2);

                    let newScript3 = document.createElement("script");
                    newScript3.src = this.script_path + "/build/app_190210_152554.js";
                    newScript3.async = false;
                    this.app_180711_232522.appendChild(newScript3);

                    this.p_190202_201350.innerHTML = [
                        "grid_190202_162410",
                        "resizeCount: " + this.resizeCount,
                        "grid_190202_162410.clientWidth: " + this.grid_190202_162410.clientWidth,
                        "grid_190202_162410.clientHeight: " + this.grid_190202_162410.clientHeight,
                        "grid_190202_162410.offsetWidth: " + this.grid_190202_162410.offsetWidth,
                        "grid_190202_162410.offsetHeight: " + this.grid_190202_162410.offsetHeight,
                        "grid_element_190210_012930",
                        "grid_element_190210_012930.clientWidth: " + this.grid_element_190210_012930.clientWidth,
                        "grid_element_190210_012930.clientHeight: " + this.grid_element_190210_012930.clientHeight,
                        "grid_element_190210_012930.offsetWidth: " + this.grid_element_190210_012930.offsetWidth,
                        "grid_element_190210_012930.offsetHeight: " + this.grid_element_190210_012930.offsetHeight,
                        "document.documentElement.clientWidth: " + document.documentElement.clientWidth,
                        "document.documentElement.clientHeight: " + document.documentElement.clientHeight,
                        "document.documentElement.offsetWidth: " + document.documentElement.offsetWidth,
                        "document.documentElement.offsetHeight: " + document.documentElement.offsetHeight,
                        "document.documentElement.scrollWidth: " + document.documentElement.scrollWidth,
                        "document.documentElement.scrollHeight: " + document.documentElement.scrollHeight,
                        "document.body.clientWidth: " + document.body.clientWidth,
                        "document.body.clientHeight: " + document.body.clientHeight,
                        "document.body.offsetWidth: " + document.body.offsetWidth,
                        "document.body.offsetHeight: " + document.body.offsetHeight,
                        "document.body.scrollWidth: " + document.body.scrollWidth,
                        "document.body.scrollHeight: " + document.body.scrollHeight,
                        "window.innerWidth: " + window.innerWidth,
                        "window.innerHeight: " + window.innerHeight,
                        "window.outerWidth: " + window.outerWidth,
                        "window.outerHeight: " + window.outerHeight,
                        "window.screen.width: " + window.screen.width,
                        "window.screen.height: " + window.screen.height,
                        "window.screen.availWidth: " + window.screen.availWidth,
                        "window.screen.availHeight: " + window.screen.availHeight
                    ].join("<br>");

                    this.load_app_180711_230000();

                    // Help GC
                    // ////////////////////////////
                    file_reader.file = null;
                    // ////////////////////////////
                }
                fileReader.readAsText(file_reader.file); 

                // test ONLY
                // ////////////////////////////
                // file_reader.file = null;
                // ////////////////////////////
            }               
        }
        file_reader.read(event.sas, "mem", this.file_receive_notifier.file_receiver.file_list, true);        
    }
    on_receive_file_in_mem_file_0001(event){
        // alert("App_Loader_180720_013150.on_receive_file_in_mem_file_0003(event) >>>>");
        let file_reader = new File_Reader_180709_202430();
        file_reader.onread_mem = (event) => {
            if(file_reader.file !== null){
                // ----------------------------------------------
                // Verify the file is correct
                // ----------------------------------------------
                // if(this.elixir_websocket.channel !== null){
                //     this.fsender.send_file_in_parallel(file_reader.file);
                // }
                // ----------------------------------------------
                // Load APP
                // ----------------------------------------------
                let fileReader = new FileReader();
                fileReader.onload = (event) => {
                    //////////////////////////////////////////////////////////
                    // [Do this will cause DOM events unresponsive]
                    //////////////////////////////////////////////////////////
                    // alert('fileReader.onload >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');   
                    // this.messagesContainer.innerHTML += ('<prep>' + fileReader.result + '</prep>');
                    // this.messagesContainer.innerHTML += ('<pre>' + fileReader.result + '</pre>');
                    // this.messagesContainer.innerHTML += ('<pre>' + " FFFFFFFFFFFFFFFFFFFF FFFFFFFF  " + '</pre>');
                    // this.messagesContainer.innerHTML += ('<iframe>' + fileReader.result + '</iframe>');

                    //////////////////////////////////////////////////////////
                    // This is OK!
                    //////////////////////////////////////////////////////////
                    // let messageItem = document.createElement("li");
                    // messageItem.innerHTML = ('<prep>' + fileReader.result + '</prep>');
                    // this.messagesContainer.appendChild(messageItem);

                    //////////////////////////////////////////////////////////
                    // This is OK!
                    //////////////////////////////////////////////////////////
                    //append html =>  app-180711-230000-d3.html
                    let messageItem = document.createElement("div");
                    messageItem.innerHTML = ('<prep>' + fileReader.result + '</prep>');
                    this.app_180711_232522.appendChild(messageItem);

                    //append lib script => d3.min.js
                    let newScript1 = document.createElement("script");
                    newScript1.src = this.script_path + "/js/d3/d3.min.js";
                    newScript1.async = false;
                    this.app_180711_232522.appendChild(newScript1);
                    
                    //append app script => app_180712_180920_d3.js
                    let newScript2 = document.createElement("script");
                    newScript2.src = this.script_path + "/build/app_180712_180920_d3.js";
                    newScript2.async = false;
                    this.app_180711_232522.appendChild(newScript2);

                    // webrtc
                    let newScript3 = document.createElement("script");
                    newScript3.src = this.script_path + "/build/app_181105_212322_webrtc.js";
                    newScript3.async = false;
                    this.app_180711_232522.appendChild(newScript3);

                    // this.load_app_180712_102226();

                    // Help GC
                    // ////////////////////////////
                    file_reader.file = null;
                    // ////////////////////////////
                }
                fileReader.readAsText(file_reader.file);
                
                // test ONLY
                // ////////////////////////////
                // file_reader.file = null;
                // ////////////////////////////
            }               
        }
        file_reader.read(event.sas, "mem", this.file_receive_notifier.file_receiver.file_list, true);        
    }
    on_receive_file_in_mem_file_0002(event){
        // alert("App_Loader_180720_013150.on_receive_file_in_mem_file_0003(event) >>>>");
        let file_reader = new File_Reader_180709_202430();
        file_reader.onread_mem = (event) => {
            if(file_reader.file !== null){
                if(this.elixir_websocket.channel !== null){
                    this.fsender.send_file_in_parallel(file_reader.file);
                }
                // test ONLY
                // ////////////////////////////
                file_reader.file = null;
                // ////////////////////////////
            }               
        }
        file_reader.read(event.sas, "mem", this.file_receive_notifier.file_receiver.file_list, true);        
    }
    on_receive_file_in_mem_file_0003(event){
        // alert("App_Loader_180720_013150.on_receive_file_in_mem_file_0003(event) >>>>");
        let file_reader = new File_Reader_180709_202430();
        file_reader.onread_mem = (event) => {
            if(file_reader.file !== null){
                if(this.elixir_websocket.channel !== null){
                    this.fsender.send_file_in_parallel(file_reader.file);
                }
                // test ONLY
                // ////////////////////////////
                file_reader.file = null;
                // ////////////////////////////
            }               
        }
        file_reader.read(event.sas, "mem", this.file_receive_notifier.file_receiver.file_list, true);        
    }
    on_chatInput_keypress(event){
        if(event.keyCode === 13){
            // -----------------------------------------------------------------------
            // <h1>[TEST] Send string as file</h1>
            // ----------------------------------------------------------------------
            // let mystring = '조선글? : @ & = + $ # 的教科書鳳凰健康';
            // let blob = new Blob([mystring], {type: 'application/octet-stream'});
            // let file_unicode_test = new File([blob], '조선글? : @ & = + $ #.txt');
            // this.file_sender.send_file_in_parallel(file_unicode_test);

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // // alert('FUCK >>>>>>>>>>>>>> ' + this.file_receive_notifier.file_receiver.file_list.length);
            // // alert('FUCK >>>>>>>>>>>>>> ' + this.file_receive_notifier.file_receiver.file_list[0].file_list[0].path);
            // if(this.file_receive_notifier.file_receiver.file_list.length > 1){
            //     let len = this.file_receive_notifier.file_receiver.file_list[0].file_list.length;
            //     alert('FUCK len >>>>>>>>>>>>>> GGYY' + len);
            //     for(let i=0; i < len; i++){
            //         if(this.file_receive_notifier.file_receiver.file_list[0].file_list[i].name !== 'index.html'){
            //             // if(this.file_receive_notifier.file_receiver.file_list[0].file_list[i].cc === this.file_receive_notifier.file_receiver.file_list[0].file_list[i].array_buffer.length){
            //                 // alert('FUCK name >>>>>>>>>>>>>> index ' + i + " >>> " + this.file_receive_notifier.file_receiver.file_list[1].file_list[i].path);   
            //                 let basename = this.get_basename(this.file_receive_notifier.file_receiver.file_list[0].file_list[i].path); 
            //                 let blob = new Blob(this.file_receive_notifier.file_receiver.file_list[0].file_list[i].array_buffer, {type: 'application/octet-stream'});
            //                 // let file = new File([blob], this.file_receive_notifier.file_receiver.file_list[1].file_list[i].name);
            //                 let file = new File([blob], basename);
            //                 this.file_sender.send_file_in_parallel(file);
            //             // }
            //         }
            //     }
            // }
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // alert("App_Loader_180720_013150.on_chatInput_keypress(event) >>>>");
            let msg = this.chatInput.value;
            this.chatInput.value = null;
            for(let i=0; i < 1; i++){
                // this.channel.push("file_transfer_server_to_client", {mid: "180518-062437", chunk: this.path_file_0000}); //<====== Send by Phoenix Channel
                // this.channel.push("file_transfer_server_to_client", {mid: "180518-062437", chunk: this.path_file_0001}); //<====== Send by Phoenix Channel
                // this.channel.push("file_transfer_server_to_client", {mid: "180518-062437", chunk: this.path_file_0002}); //<====== Send by Phoenix Channel
                // this.channel.push("file_transfer_server_to_client", {mid: "180518-062437", chunk: this.path_file_0003}); //<====== Send by Phoenix Channel
                // ////////////////////////////////////////////////////////////////////////////////////////
                // [Test] Send Binary To Server 
                //  22 5B 22 31 22 2C 22 36 22 2C 22 72 6F 6F 6D 3A 6C 6F 62 62 79 22 2C 22 66 74 70 43 74 53 22 2C 5B 37 32 2C 36 39 5D 5D 22
                // ////////////////////////////////////////////////////////////////////////////////////////
                // let buffer = new ArrayBuffer(2);
                // let bufArray = new Uint8Array(buffer);
                // bufArray[0] = 0x48;
                // bufArray[1] = 0x45;
                // let bufArray = new Uint8Array([0x22,0x5B,0x22,0x31,0x22,0x2C,0x22,0x36,0x22,0x2C,0x22,0x72,0x6F,0x6F,0x6D,0x3A,0x6C,0x6F,0x62,0x62,0x79,0x22,0x2C,0x22,0x66,0x74,0x70,0x43,0x74,0x53,0x22,0x2C,0x5B,0x37,0x32,0x2C,0x36,0x39,0x5D,0x5D,0x22]);
                // this.channel.push("ftpCtS", {mid: "180706-183324", chunk: "H"});
                // this.channel.push("ftpCtS", {mid: "180706-183324", chunk: bufArray});
                // this.channel.push("ftpCtS", bufArray);
                // this.phoenix_websocket.ws.push(bufArray);
                // ///////////////////////////////////////////////////////////////////////////////////////
                // let encoded_jason = JSON.stringify({name: file_name_uri_component_encoded_and_base64, 
                //                                     chunk: "",
                //                                     mid: "180427-120518", 
                //                                     uuid: this.uuid, 
                //                                     cid: this.currentBodyId, 
                //                                     cs: this.cs, 
                //                                     fs: this.fileSelected.size, 
                //                                     mcs: this.MAX_FILE_CHUNK_SIZE});
                // ///////////////////////////////////////////////////////////////////////////////////////
                if(this.elixir_websocket.channel !== null){
                    // let obj = { "username":"John", "email":"abc@gmail.com", "message":"hello"};
                    // this.elixir_websocket.channel.send(JSON.stringify(obj));

                    // this.elixir_websocket.channel.send("XXXXX");
                    for(let y=0; y<1; y++){
                        // this.elixir_websocket.channel.send(msg);
                        // this.elixir_websocket.channel.send(y); 
                        // this.elixir_websocket.channel.send(JSON.stringify({
                        //     name:this.path_file_0000,
                        //     dir:"",
                        //     chunk:"",
                        //     mid:"180518-062437",
                        //     uuid:this.get_uuid(),
                        //     cid:1,
                        //     cs:0,
                        //     fs:0,
                        //     ilc:true,
                        //     mcs:8*1024,
                        //     cc:1,
                        //     ct:"",
                        //     at:"",
                        //     mt:"",
                        //     pms:777,
                        //     sas:"",
                        //     wm:"ow"}));
                        this.elixir_websocket.channel.send(JSON.stringify({
                            name:this.path_file_0001,
                            dir:"",
                            chunk:"",
                            mid:"180518-062437",
                            uuid:this.get_uuid(),
                            cid:1,
                            cs:0,
                            fs:0,
                            ilc:true,
                            mcs:8*1024,
                            cc:1,
                            ct:"",
                            at:"",
                            mt:"",
                            pms:777,
                            sas:"",
                            wm:"ow"}));  
                        // this.elixir_websocket.channel.send(JSON.stringify({
                        //     name:this.path_file_0002,
                        //     dir:"",
                        //     chunk:"",
                        //     mid:"180518-062437",
                        //     uuid:this.get_uuid(),
                        //     cid:1,
                        //     cs:0,
                        //     fs:0,
                        //     ilc:true,
                        //     mcs:8*1024,
                        //     cc:1,
                        //     ct:"",
                        //     at:"",
                        //     mt:"",
                        //     pms:777,
                        //     sas:"",
                        //     wm:"ow"}));
                        // this.elixir_websocket.channel.send(JSON.stringify({
                        //     name:this.path_file_0003,
                        //     dir:"",
                        //     chunk:"",
                        //     mid:"180518-062437",
                        //     uuid:this.get_uuid(),
                        //     cid:1,
                        //     cs:0,
                        //     fs:0,
                        //     ilc:true,
                        //     mcs:8*1024,
                        //     cc:1,
                        //     ct:"",
                        //     at:"",
                        //     mt:"",
                        //     pms:777,
                        //     sas:"",
                        //     wm:"ow"}));
                    }
                }else{
                }
                /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            }
        }
    }
    load_app_180711_232522(){
        // alert('App_Loader_180720_013150.load_app_180711_232522()');
        this.elixir_websocket.channel.send(JSON.stringify({
            name:this.path_file_0000,
            dir:"",
            chunk:"",
            mid:"180518-062437",
            uuid:this.get_uuid(),
            cid:1,
            cs:0,
            fs:0,
            ilc:true,
            mcs:8*1024,
            cc:1,
            ct:"",
            at:"",
            mt:"",
            pms:777,
            sas:"",
            wm:"ow"}));
    }
    load_app_180711_230000(){
        this.elixir_websocket.channel.send(JSON.stringify({
            name:this.path_file_0001,
            dir:"",
            chunk:"",
            mid:"180518-062437",
            uuid:this.get_uuid(),
            cid:1,
            cs:0,
            fs:0,
            ilc:true,
            mcs:8*1024,
            cc:1,
            ct:"",
            at:"",
            mt:"",
            pms:777,
            sas:"",
            wm:"ow"}));
    }
    on_dom_loaded(event){
        // alert('FUCK >>>>>>>>>>>>>> on_dom_loaded(event)');
        if(this.elixir_websocket.channel !== null){
            // // alert('this.elixir_websocket.channel !== null');
            this.load_app_180711_232522();  //<============================== 190207-234234
            // window.runWasm();
        }else{
            this.wait_elixir_channel = 
            setInterval(() => { 
                if(this.elixir_websocket.channel !== null){
                    // alert('this.elixir_websocket.channel !== null >>>>>');
                    clearInterval(this.wait_elixir_channel);
                    this.wait_elixir_channel = null;
                    // // this.load_app_180711_230000();
                    this.load_app_180711_232522(); //<================================ 190207-234234
                }else if(this.elixir_ws_total_wait > this.elixir_ws_max_wait){
                    clearInterval(this.wait_elixir_channel);
                    alert('Server is unresponsive >>>>>>>'); //<== websocket app 這個盡量不要用
                }
                this.elixir_ws_total_wait += 50;
            },  50);
        }
    }
}
