export default class File_Reader_180709_202430 {
    constructor() {
        // alert("File_Reader_180709_202430.constructor() >>>>");
        this.cc = null;
        this.db = null;
        this.db_delete = null;
        this.db_name = 'file-system';
        this.db_open = null;
        this.file = null;
        this.file_index = null;
        this.file_info_objectstore_name = "file-info";
        this.file_name = null;
        this.file_path = null;
        this.file_path_no_basename = null;
        this.file_trunks_objectstore_name = "file-trunks";
        this.file_uuid = null;
        this.folder_index = null;
        this.is_db_exist = null;
        this.onread_db = null;
        this.onread_mem = null;
        this.store = null;
        this.transaction = null;
        this.transaction_id = null;
    }
    read(file_path, read_from, file_list, is_delete_on_read){
        // alert("File_Reader_180609_040044.get_file(file_path, file_sender) >>>>");
        this.file_path = this.get_path_with_head_slash(file_path);
        this.file_name = this.get_basename(file_path);
        this.file_path_no_basename = this.get_path_no_basename(file_path);
        ///////////////////One Folder One Database///////////////////
        // this.db_name = this.get_path_no_basename(file_path);
        /////////////////////////////////////////////////////////////
        // if(this.db_name === null) {
        //     return;
        // }
        // this.open_db_do_transaction("180530-031240");

        if(read_from === "mem"){
            //search folder
            let result = "000000-000000";
            this.folder_index = this.search_folder(this.file_path_no_basename, file_list);
            if(this.folder_index === null){
                result = "180615-175608";
            }else{
                //search file
                this.file = this.search_file(file_path, file_list, this.folder_index, is_delete_on_read);
            }
            if(this.onread_mem !== null){
                let event = {result: result, file: this.file};
                this.onread_mem(event);
            }
        }else if(read_from === "db"){
            if(this.db_name === null) {
                if(this.onread_db !== null){
                    this.onread_db("180615-161750");
                }
            }else{
                this.open_db_do_transaction("180530-031240");
            }
        }
    }
    open_db_do_transaction(transaction_id){
        if (!window.indexedDB) {
            alert("[error][180601-145000] Your browser doesn't support IndexedDB");
            return;
        }
        this.transaction_id = transaction_id;
        this.is_db_exist = true;
        this.db_open = window.indexedDB.open(this.db_name);
        this.db_open.onupgradeneeded = (event) => {this.on_db_open_upgradeneeded(event)};
        this.db_open.onsuccess = (event) => {this.on_db_open_success(event)};
        this.db_open.onerror = (event) => {this.on_db_open_error(event)};
    }
    on_db_open_upgradeneeded(event){
        this.is_db_exist = false;
        this.db = this.db_open.result;
        //////////////////////////////////////////////////////////////////////////////////////////////////
        //Do this to prevent other updating IndexedDB (越少 update database 越好)
        if(this.db.objectStoreNames.contains(this.file_info_objectstore_name) === false){
            let store = this.db.createObjectStore(this.file_info_objectstore_name, {keyPath: "path"});
        }
        if(this.db.objectStoreNames.contains(this.file_trunks_objectstore_name) === false){
            let store = this.db.createObjectStore(this.file_trunks_objectstore_name);
        }
        ///////////////////////////////////////////////////////////////////////////////////////////////////
    }
    on_db_open_success(event){
        // alert("File_Reader_180601_143103.on_db_open_success(event) >>>> " + this.is_db_exist);
        this.db = this.db_open.result;
        if(this.is_db_exist === false){
            let transaction_result = '180611-175700';
            this.close_db();
            if(this.onread_db !== null){
                this.onread_db(transaction_result);
            }
        }else{
            this.do_transaction(this.transaction_id, event);
        }
    }
    on_db_open_error(event){
    }
    on_db_delete_success(event){
        // alert("File_Reader_180528_090505.on_db_delete_success(event) >>>>");
    }
    do_transaction(transaction_id, event){
        // alert("File_Reader_180601_143103.do_transaction(transaction_id, event) >>>>");
        this.transaction_id = transaction_id;  
        if(transaction_id === "180530-031240"){
            //------------------------------------------------------------
            // <h1>Get File Info</h1>
            //------------------------------------------------------------
            // alert('transaction_id === "180530-031240"');
            let transaction_result = null;
            let transaction = null;
            let store_file_info = null;
            let store_file_trunks = null;
            let get_file_info = null;
            let array_of_arraybuffer = [];
            let get_all_chunks = null;
            if((this.db.objectStoreNames.contains(this.file_info_objectstore_name) === false)||(this.db.objectStoreNames.contains(this.file_trunks_objectstore_name) === false)){
                // alert('is_objectstore_exist === false');
                transaction_result = "180602-053205";
                this.close_db();
                if(this.onread_db !== null){
                    this.onread_db(transaction_result);
                }
            }else{
                //------------------------------------------------------------
                // <h1>Original Version</h1>
                //------------------------------------------------------------
                transaction = this.db.transaction([this.file_info_objectstore_name, this.file_trunks_objectstore_name], "readonly");
                store_file_info = transaction.objectStore(this.file_info_objectstore_name);
                store_file_trunks = transaction.objectStore(this.file_trunks_objectstore_name);
                get_file_info = store_file_info.get(this.file_path);
                get_file_info.onsuccess = (event) => {
                    // alert('get_file_info.onsuccess = (event)');
                    transaction_result = "000000-000000";
                    // alert("FUCK >> get_file_info.result.uuid: " + get_file_info.result.uuid);
                    // alert("FUCK >> event.target.result.uuid: " + event.target.result.uuid);
                    // alert("FUCK >> event.target.result: " + event.target.result);
                    ////////////////////////////////////////////////////////////////
                    // you may get nothing
                    ////////////////////////////////////////////////////////////////
                    if(event.target.result === undefined){
                        // alert("FUCK >> event.target.result: " + event.target.result);
                        transaction_result = "180611-181935";
                    }else{
                        ////////////////////////////////////////////////////////////////
                        // get the data
                        ////////////////////////////////////////////////////////////////
                        this.file_uuid = event.target.result.uuid;
                        this.cc = event.target.result.cc;
                        for(let i = 0; i < this.cc; i++){
                            let get_chunk = store_file_trunks.get(this.file_uuid + "-" + (i+1));
                            get_chunk.onsuccess = (event) => {
                                if(event.target.result === undefined){
                                    transaction_result = "180609-074000"
                                }else{
                                    array_of_arraybuffer[i] = event.target.result;
                                }
                            }
                            get_chunk.onerror = (event) => {
                                transaction_result = "180609-061504"
                            }
                        }
                    }
                }
                get_file_info.onerror = (event) => {
                    alert('get_file_info.onerror >>>> ' + event.target.result);
                    transaction_result = "180601-152630"
                }

                transaction.oncomplete = (event) => {
                    if(transaction_result === "000000-000000"){
                        let blob = new Blob(array_of_arraybuffer, {type: 'application/octet-stream'});
                        this.file = new File([blob], this.file_name);
                    }else if(transaction_result === "180609-074000"){
                        this.file = null;
                    }else{
                        this.file = null;
                    }
                    this.close_db();
                    if(this.onread_db !== null){
                        this.onread_db(transaction_result);
                    }
                };
            }
        }else{
            this.close_db();
        }
    }
    close_db(){
        this.db.close();
        this.db = null;
        this.db_open = null;
    }
    get_path_with_head_slash(file_path){
        if((file_path === "")||(file_path === null)){
            // alert("[error][180601-152300] Invalid file path!");
            return null;
        }
        if(file_path.charAt(0) !== '/'){
            file_path = '/' + file_path;
        }
        return file_path;
    }
    get_path_no_basename(file_path){
        let path_no_basename = '';
        let path_components = [];

        if((file_path === "")||(file_path === null)){
            // alert("[error][180528-192126] Invalid file path!");
            return null;
        }
        // alert("file_path.charCodeAt(0) >>> " + file_path.charCodeAt(0));
        if(file_path.charAt(0) !== '/'){
            file_path = '/' + file_path;
        }
        // alert("file_path >>> " + file_path);

        //------------------------------------------------------------
        // <h1>File Path No Basename | Method 1</h1>
        //------------------------------------------------------------
        // path_components = file_path.split("/");
        // if(path_components.length === 2){
        //     path_no_basename = '/';
        // }else{
        //     for(let i=1; i < (path_components.length-1); i++){
        //         path_no_basename = path_no_basename + '/' + path_components[i];
        //     }
        // }
        // alert("path_components.length >>> " + path_components.length);
        // alert("path_components >>> " + path_components);

        //------------------------------------------------------------
        // <h1>File Path No Basename | Method 2</h1>
        //------------------------------------------------------------
        let last_slash_index = file_path.lastIndexOf("/");
        // alert("last_slash_index >>> " + last_slash_index);
        if(last_slash_index === 0){
            path_no_basename = '/';
        }else{
            path_no_basename = file_path.slice(0, last_slash_index);
        }
        // alert("path_no_basename >>> " + path_no_basename);
        return path_no_basename;
    }
    get_basename(file_path){
        if((file_path === "")||(file_path === null)){
            alert("[error][180528-192133] Invalid file path!");
            return null;
        }
        //------------------------------------------------------------
        // <h1>File Basename | Method 1 | Not Work</h1>
        //------------------------------------------------------------
        // let file_name = file_path.substring(file_path.lastIndexOf('/'));

        //------------------------------------------------------------
        // <h1>File Basename | Method 2 | OK!</h1>
        //------------------------------------------------------------
        // let path_components = file_path.split("/");
        // let basename = path_components[path_components.length-1];

        //------------------------------------------------------------
        // <h1>File Basename | Method 2 | OK!</h1>
        //------------------------------------------------------------
        if(file_path.charAt(0) !== '/'){
            file_path = '/' + file_path;
        }
        let last_slash_index = file_path.lastIndexOf("/");
        let basename = file_path.slice(last_slash_index+1, file_path.length);
        // alert("basename >>> " + basename);
        return basename;
    }
    search_folder(file_path_no_basename, file_list){
        // alert("File_Reader_From_Mem_180614_173540.search_folder(file_path_no_basename) >>>>");
        let len = file_list.length;
        // alert('len >>> ' + len);
        // alert('file_path_no_basename >>> ' + file_path_no_basename);
        for(let i=0; i < len; i++){
            if(file_path_no_basename === file_list[i].folder){
                return i;
            }      
        }
        return null;
    }
    search_file(file_path, file_list, folder_index, is_delete_on_read){
        // alert("File_Reader_From_Mem_180614_173540.search_file(file_path, file_list, folder_index) >>>>");
        if((file_path === "")||(file_path === null)||(file_path === undefined)){
            return null;
        }
        if(folder_index > (file_list.length - 1)){
            return null;
        }else{
            // alert('file_list[folder_index].file_list.length >>>> ' + file_list[folder_index].file_list.length);
            let len = file_list[folder_index].file_list.length;
            for(let i=0; i < len; i++){
                if(file_path === file_list[folder_index].file_list[i].path){
                    // alert('file found >>>> ' + file_path);
                    this.file_index = i;
                    let basename = this.get_basename(file_list[folder_index].file_list[i].path);
                    let blob = new Blob(file_list[folder_index].file_list[i].array_buffer, {type: 'application/octet-stream'});
                    // let file = new File([blob], file_list[folder_index].file_list[i].name);  //this is the original file name from server
                    let file = new File([blob], basename);
                    // // alert('file_list[folder_index].file_list.length >>>> ' + file_list[folder_index].file_list.length);
                    // // alert('file_list[folder_index].file_list[i].cc >>>> ' + file_list[folder_index].file_list[i].cc);
                    // // alert('file_list[folder_index].file_list[i].array_buffer.length >>>> ' + file_list[folder_index].file_list[i].array_buffer.length);
                    if(file_list[folder_index].file_list[i].cc !== file_list[folder_index].file_list[i].array_buffer.length){
                        // // alert('Really Fuck >>>>>>>>>>>>>>>> ');
                        // // ///////////////////////////////////////////////////////////
                        // file_list[folder_index].file_list[i].array_buffer = null;
                        // // ///////////////////////////////////////////////////////////
                        // file_list[folder_index].file_list.splice(i, 1);
                        blob = null;
                        file = null;
                        return null;
                    }
                    if(is_delete_on_read === true){
                        // if(file_list[folder_index].file_list[i].count_of_request_delete_after_read <= 1){
                        //     file_list[folder_index].file_list.splice(i, 1);
                        //     alert('Really Fuck >>>>>>>>>>>>>>>> ');
                        // }else{
                        //     file_list[folder_index].file_list[i].count_of_request_delete_after_read -= 1;
                        // }
                        // alert('Really Fuck >>>>>>>>>>>>>>>> ');
                        // ///////////////////////////////////////////////////////////
                        file_list[folder_index].file_list[i].array_buffer = null;
                        // ///////////////////////////////////////////////////////////
                        file_list[folder_index].file_list.splice(i, 1);
                    }
                    return file;               
                }      
            }
        }
        return null;
    }

}