// import File_Reader_180709_202430 from "./file_reader_180709_202430"
import File_Writer_180709_202054 from "./file_writer_180709_202054"

export default class FReceiver_180725_083440 {
    constructor() {
        // alert("FReceiver_180725_083440.constructor() >>>>");
        // this.phoenix_websocket = null;
        this.elixir_websocket = null;
        this.channel = null;
        this.file_list = [];
        this.onreceive_file_in_db = null;
        this.onreceive_file_in_memory = null;
    }
    init(elixir_websocket){
        // this.phoenix_websocket = phoenix_websocket;
        // this.channel = phoenix_websocket.channel;
        // this.channel.on("new_msg", payload => {this.on_channel_in(payload)});
        // this.file_list = {push: this.file_list.push = (payload) => {this.onkeep(payload)}, file_list: []};
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        this.elixir_websocket = elixir_websocket;
        this.elixir_websocket.ws.addEventListener('message', event => {this.on_elixir_in(event)}); //callback was added to list
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    }
    // get_uuid(){
    //     let dt = new Date().getTime();
    //     let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    //         let r = (dt + Math.random()*16)%16 | 0;
    //         dt = Math.floor(dt/16);
    //         return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    //     });
    //     return uuid;
    // }
    // init(phoenix_websocket, elixir_websocket){
    //     this.phoenix_websocket = phoenix_websocket;
    //     this.channel = phoenix_websocket.channel;
    //     this.channel.on("new_msg", payload => {this.on_channel_in(payload)});
    //     // this.file_list = {push: this.file_list.push = (payload) => {this.onkeep(payload)}, file_list: []};
    //     //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //     this.elixir_websocket = elixir_websocket;
    //     this.elixir_websocket.ws.addEventListener('message', event => {this.on_elixir_in(event)}); //callback was added to list
    //     //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // }
    on_channel_in(payload){
        if(payload.mid == "180518-061327"){
            // alert('payload.mid == "180518-061327" >>>  Get file trunk');
            // alert('payload.dir >>>>>>>>>>>>>>>>>>> ' + payload.dir);
            // let path_no_basename = "/home/james/Desktop/binary-data/"; // "/temp/"  "/"  "/home/james/Desktop/binary-data/"
            let path_no_basename = payload.dir; // "/temp/"  "/"  "/home/james/Desktop/binary-data/"

            //[one file => one store]
            ///////////////////////////////////////////////////////////////////////////////////////////////////
            // let file_writer = new File_Writer_180530_140835();
            // file_writer.write(path_no_basename, payload, this, "arraybuffer");  //"blob" "base64" "arraybuffer"

            //[multiple files => one store][File in n trunks]
            ///////////////////////////////////////////////////////////////////////////////////////////////////
            // let file_writer = new File_Writer_180608_200200();
            // file_writer.onwrite = (event) => {
            //     if(event.result === "000000-000000"){
            //         // alert('event.result === "000000-000000" >>>>>>>>>>>>>>>');
            //         // -----------------------------------------------------------------------
            //         // <h1>Tell server to send next chunk</h1>
            //         // ----------------------------------------------------------------------
            //         this.channel.push("file_transfer_server_to_client", {mid: "180523-161850", file_pusher_pid: event.file_pusher_pid});
            //     }else{
            //         alert("[error][180528-191900] File transferring failed >>>> [result]" + event.result);
            //     }
            // };
            // file_writer.write(path_no_basename, payload);

            // -----------------------------------------------------------------------
            // <h1>File_Reader | one file => one store</h1>
            // ----------------------------------------------------------------------
            let write_to = 'mem';
            let file_writer = new File_Writer_180709_202054();
            file_writer.onwrite_db = (event) => {
                if((write_to === 'db')||(write_to === 'mem_db')||(write_to === 'db_mem')){
                    if(event.result === "000000-000000"){
                        // alert('event.result === "000000-000000" >>>>>>>>>>>>>>>');
                        ///////////////////////////////////////////////////////////////////////////////
                        // <h1>Tell server => to send next chunk</h1>
                        ///////////////////////////////////////////////////////////////////////////////
                        this.channel.push("file_transfer_server_to_client", {mid: "180523-161850", sas: "undefined", file_pusher_pid: event.file_pusher_pid});
                    }else{
                        ///////////////////////////////////////////////////////////////////////////////
                        // <h1>Tell server => to stop and cleanup</h1>
                        ///////////////////////////////////////////////////////////////////////////////
                        this.channel.push("file_transfer_server_to_client", {mid: "180612-151040", sas: "undefined", file_pusher_pid: event.file_pusher_pid});
                    }
                }
            };
            file_writer.onwrite_memory = (event) => {
                // //if the last trunk is kept, call this.onreceive_file_in_memory
                // // alert('file_writer.onwrite_memory = (event) >>>> ' + event);
                // // alert('this.file_list.length >>>> ' + this.file_list.length);
                // if(this.file_list.length > 0){
                //     // alert('this.file_list[0].folder >>>> ' + this.file_list[0].folder);
                //     // alert('this.file_list[0].file_list[0].path >>>> ' + this.file_list[0].file_list[0].path);
                //     // alert('this.file_list[0].file_list[0].dir >>>> ' + this.file_list[0].file_list[0].dir);
                //     // alert('this.file_list[0].file_list[0].fs >>>> ' + this.file_list[0].file_list[0].fs);
                //     // alert('this.file_list[0].file_list[0].name >>>> ' + this.file_list[0].file_list[0].name);
                //     // alert('this.file_list[0].file_list[0].uuid >>>> ' + this.file_list[0].file_list[0].uuid);
                // }
                if(write_to === 'mem'){
                    if(event.result === "000000-000000"){
                        // alert('event.result === "000000-000000" >>>>>>>>>>>>>>>');
                        // alert('event.sas >>>>>>>>>>>>>>>' + event.sas);
                        ///////////////////////////////////////////////////////////////////////////////
                        // <h1>Tell server => to send next chunk</h1>
                        ///////////////////////////////////////////////////////////////////////////////
                        this.channel.push("file_transfer_server_to_client", {mid: "180523-161850", sas: event.sas, file_pusher_pid: event.file_pusher_pid});
                    }else{
                        ///////////////////////////////////////////////////////////////////////////////
                        // <h1>Tell server => to stop and cleanup</h1>
                        ///////////////////////////////////////////////////////////////////////////////
                        // alert('event.result >>>>>>>>>>>>>>>' + event.result);
                        this.channel.push("file_transfer_server_to_client", {mid: "180612-151040", sas: event.sas, file_pusher_pid: event.file_pusher_pid});
                    }
                }
            };
            file_writer.write(path_no_basename, payload, write_to, this.file_list);

        }else if(payload.mid == "180528-190534"){
            // alert("FTP Done >>>> " + payload.name);
            // alert('payload.mid == "180528-190534"');
            // alert('payload.sas >>>>>>> ' + payload.sas);
            ///////////////////////////////////////////////////////////////////////////////
            // <h1>Tell server => to finish and cleanup</h1>
            ///////////////////////////////////////////////////////////////////////////////
            this.channel.push("file_transfer_server_to_client", payload);

            // -----------------------------------------------------------------------
            // <h1>File_Reader | one file => one store</h1>
            // ----------------------------------------------------------------------
            // let file_reader_180601_144040 = new File_Reader_180601_143103();
            // file_reader_180601_144040.get_file("/temp/" + payload.name, this.file_sender, "arraybuffer");

            // -----------------------------------------------------------------------
            // <h1>File_Reader | multiple files => one store</h1>
            // ----------------------------------------------------------------------
            // // alert('payload.dir >>>>>>>>>>>>>>>>>>> ' + payload.dir);
            // let file_reader = new File_Reader_180609_040044();
            // file_reader.onread = (event) => {  
            //     // alert("file_reader.onload >>>>>>>>>>> " + event);
            //     if(file_reader.file !== null){
            //         // ----------------------------------------------------------------------
            //         // <h1>[Test] Make sure the file is correct</h1>
            //         // ----------------------------------------------------------------------
            //         this.file_sender.send_file_in_parallel(file_reader.file);

            //         // ----------------------------------------------------------------------
            //         // <h1>This will make whole page unresponsive</h1>
            //         // ----------------------------------------------------------------------
            //         let fileReader = new FileReader();
            //         fileReader.onload = (event) => {
            //             //////////////////////////////////////////////////////////
            //             // [Do this will cause DOM events unresponsive]
            //             //////////////////////////////////////////////////////////
            //             // alert('fileReader.onload >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');   
            //             // this.messagesContainer.innerHTML += ('<prep>' + fileReader.result + '</prep>');
            //             // this.messagesContainer.innerHTML += ('<pre>' + fileReader.result + '</pre>');
            //             // this.messagesContainer.innerHTML += ('<pre>' + " FFFFFFFFFFFFFFFFFFFF FFFFFFFF  " + '</pre>');
            //             // this.messagesContainer.innerHTML += ('<iframe>' + fileReader.result + '</iframe>');

            //             //////////////////////////////////////////////////////////
            //             // This is OK!
            //             //////////////////////////////////////////////////////////
            //             // let messageItem = document.createElement("li");
            //             // messageItem.innerHTML = ('<prep>' + fileReader.result + '</prep>');
            //             // this.messagesContainer.appendChild(messageItem);

            //             //////////////////////////////////////////////////////////
            //             // This is OK!
            //             //////////////////////////////////////////////////////////
            //             // this.messagesContainer.innerHTML = ('<prep>' + fileReader.result + '</prep>');
            //         }
            //         fileReader.readAsText(file_reader.file); 
            //     }
            // }
            // file_reader.read(payload.dir + "/" + payload.name);

            // -----------------------------------------------------------------------
            // <h1>File Receive Notifier</h1>
            // ----------------------------------------------------------------------
            if(payload.chunk === '[file transfer done]'){
                // alert("FTP Done >>>> " + payload.sas);
                if(this.onreceive_file_in_mem !== null){
                    this.onreceive_file_in_mem(payload);
                }
            }else if(payload.chunk === '[file transfer stop]'){
                // -----------------------------------------------------------------------
                // <h1>File was overwrited</h1>
                // 這種情形, [不需要]通知收件人, 因爲會有一份最新的到收件人手上
                // ----------------------------------------------------------------------
                // // alert("FTP Stop >>>> " + payload.name); 
                // alert("FTP Stop >>>> " + payload.sas);
            }else if(payload.chunk === '[file transfer error][file updated]'){
                // -----------------------------------------------------------------------
                // <h1>File was updated</h1>
                // 這種情形, [不需要]通知收件人, server必須自己重送最新的一份
                // ----------------------------------------------------------------------
                // alert("FTP Error >>>> " + payload.sas);
                ///////////////////////////////////////////////////////////////////
                // 1. this will cleanup the uncompleted file
                // 2. ONLY registered file_reader (to file_receive_notifier) can set [is_delete_on_read] flag to true
                ///////////////////////////////////////////////////////////////////
                // let file_reader = new File_Reader_180709_202430();
                // file_reader.read(payload.sas, "mem", this.file_list, false);
            }
        }
    }
    uint8array_to_ascii_string(payload){
        let string = '';
        // alert('uint8array_to_ascii_string >>>>>>>>>>>>>> ');
        if(payload.byteLength === 0){
            return null;
        }
        for (let i=0; i<payload.byteLength; i++) {
            string += String.fromCharCode(payload[i]);
        }
        return string;
    }
    arraybuffer_to_base64(payload){
        return window.btoa(String.fromCharCode.apply(null, new Uint8Array(payload)));
    }
    on_elixir_in(event){
        // alert('Shit >>>>>>>>>>>>>> ' + typeof(event.data));
        // alert('Shit >>>>>>>>>>>>>> ' + event.data);
        // alert('event.data.byteLength >>>>>>>>>>>>>> ' + event.data.byteLength);
        if(typeof(event.data) === 'object'){
            // /////////////////////////////////////////////////////////////////////////////////////////////////
            // => [Elixir Cowboy] don't need FileReader to transform the raw data to ArrayBuffer
            // /////////////////////////////////////////////////////////////////////////////////////////////////
            // let fileReader_ArrayBuffer = new FileReader();
            // fileReader_ArrayBuffer.onload = (event) => {
                // alert('FUCK [2] >>>>>>>>>>>>>> ');
                let byte_array = new Uint8Array(event.data);
                // alert('byte_array.byteLength >>>>>>>>>>>>>> ' + byte_array.byteLength);
                // alert('byte_array[0] >>>>>>>>>>>>>> ' + byte_array[0]);
                // alert('typeof(byte_array[0]) >>>>>>>>>>>>>> ' + typeof(byte_array[0]));
                if(byte_array[0] === 123){
                    // alert('byte_array[0] ==> {');
                    let bytesLen = byte_array.byteLength;
                    let indexOfJasonEnd = 0;
                    let jason = '';
                    // let rawData = null;
                    for(let i=0; i<bytesLen; i++){
                        if(byte_array[i] === 125){
                            jason += String.fromCharCode(byte_array[i]);
                            indexOfJasonEnd = i;
                            break;
                        }else{
                            jason += String.fromCharCode(byte_array[i]);
                        }
                    }
                    // alert('jason >>>>>>>>>>>>>> ' + jason);
                    // ---------------------------------------------------------------
                    // => need to handle different cases
                    // ---------------------------------------------------------------
                    if(indexOfJasonEnd == 0){

                    }else if(indexOfJasonEnd == (bytesLen-1)){

                    }else{
                        // alert('indexOfJasonEnd >>>>>>>>>>>>>> ' + indexOfJasonEnd);
                        // let jason = byte_array.subarray(0, indexOfJasonEnd+1);
                        // rawData = byte_array.subarray(indexOfJasonEnd+1, byte_array.byteLength);
                        let rawData_arraybuffer = event.data.slice(indexOfJasonEnd+1, byte_array.byteLength)
                        // let fileInfo = new TextDecoder("utf-8").decode(jason); //<== not support by IE and Safari
                        // let fileInfo = this.uint8array_to_ascii_string(jason);
                        // alert('jason >>>>> ' + jason);
                        let fileInfo = JSON.parse(jason); 
                        // alert('fileInfo.name >>>>> ' + fileInfo.name);
                        let fName = decodeURIComponent(fileInfo.name);
                        let fDir = decodeURIComponent(fileInfo.dir);
                        let ilc = null;
                        if(fileInfo.ilc === true){
                            ilc = "true";
                        }else{
                            ilc = "false";
                        }
                        // alert('fName >>>>> ' + fName); 
                        // alert('fDir >>>>> ' + fDir);
                        // alert('fileInfo.mid >>>>> ' + fileInfo.mid);
                        // alert('fileInfo.uuid >>>>> ' + fileInfo.uuid);
                        // alert('fileInfo.ilc >>>>>> ' + fileInfo.ilc);
                        // alert('fileInfo.fs >>>>>> ' + fileInfo.fs); 
                        // alert('fileInfo.mcs >>>>>> ' + fileInfo.mcs);
                        // alert('fileInfo.wm >>>>>> ' + fileInfo.wm); 
                        // alert('fileInfo.cs >>>>>> ' + fileInfo.cs);
                        // alert('fileInfo.cc >>>>>> ' + fileInfo.cc);
                        // alert('fileInfo.spid >>>>>> ' + fileInfo.spid);
                        // alert('rawData_arraybuffer.byteLength >>>>>> ' + rawData_arraybuffer.byteLength);
                        if(fileInfo.mid === "180518-061327"){
                            let path_no_basename = fDir;
                            let write_to = 'mem';
                            // alert('fileInfo.cs >>>>>> ' + fileInfo.cs);
                            // alert('rawData.byteLength >>>>>> ' + rawData.byteLength);
                            // let test_array = new Uint8Array(rawData.buffer); 
                            // alert('test_array.byteLength >>>>>> ' + test_array.byteLength);
                            // let rawData_arraybuffer = new ArrayBuffer(rawData.byteLength);
                            // rawData.map((value, i) => {rawData_arraybuffer[i] = value});
                            // let rawData_arraybuffer = rawData.buffer.slice(indexOfJasonEnd+1, byte_array.byteLength)
                            let payload = {mid: fileInfo.mid,
                                        //    chunk: rawData.buffer,  //<===== wrong !!!
                                           chunk: rawData_arraybuffer,
                                           cf: "arraybuffer",
                                           cid: fileInfo.cid,
                                           cs: fileInfo.cs,
                                           cc: fileInfo.cc,
                                           uuid: fileInfo.uuid,
                                           name: fName,
                                           dir: fDir,
                                           fs: fileInfo.fs,
                                           mt: "",
                                           wm: fileInfo.wm,
                                           ilc: ilc,
                                           file_pusher_pid: fileInfo.spid
                            };
                            let file_writer = new File_Writer_180709_202054();
                            file_writer.onwrite_db = (event) => {
                                if((write_to === 'db')||(write_to === 'mem_db')||(write_to === 'db_mem')){
                                    if(event.result === "000000-000000"){
                                        // alert('event.result === "000000-000000" >>>>>>>>>>>>>>>');
                                        ///////////////////////////////////////////////////////////////////////////////
                                        // <h1>Tell server => to send next chunk</h1>
                                        ///////////////////////////////////////////////////////////////////////////////
                                        // this.channel.push("file_transfer_server_to_client", {mid: "180523-161850", sas: "undefined", file_pusher_pid: event.file_pusher_pid});
                                    }else{
                                        ///////////////////////////////////////////////////////////////////////////////
                                        // <h1>Tell server => to stop and cleanup</h1>
                                        ///////////////////////////////////////////////////////////////////////////////
                                        // this.channel.push("file_transfer_server_to_client", {mid: "180612-151040", sas: "undefined", file_pusher_pid: event.file_pusher_pid});
                                    }
                                }
                            };
                            file_writer.onwrite_memory = (event) => {
                                // //if the last trunk is kept, call this.onreceive_file_in_memory
                                // // alert('file_writer.onwrite_memory = (event) >>>> ' + event);
                                // // alert('this.file_list.length >>>> ' + this.file_list.length);
                                // if(this.file_list.length > 0){
                                //     // alert('this.file_list[0].folder >>>> ' + this.file_list[0].folder);
                                //     // alert('this.file_list[0].file_list[0].path >>>> ' + this.file_list[0].file_list[0].path);
                                //     // alert('this.file_list[0].file_list[0].dir >>>> ' + this.file_list[0].file_list[0].dir);
                                //     // alert('this.file_list[0].file_list[0].fs >>>> ' + this.file_list[0].file_list[0].fs);
                                //     // alert('this.file_list[0].file_list[0].name >>>> ' + this.file_list[0].file_list[0].name);
                                //     // alert('this.file_list[0].file_list[0].uuid >>>> ' + this.file_list[0].file_list[0].uuid);
                                // }
                                if(write_to === 'mem'){
                                    if(event.result === "000000-000000"){
                                        // alert('event.result === "000000-000000" >>>>>>>>>>>>>>>');
                                        // alert('event.sas >>>>>>>>>>>>>>>' + event.sas);
                                        ///////////////////////////////////////////////////////////////////////////////
                                        // <h1>Tell server => to send next chunk</h1>
                                        ///////////////////////////////////////////////////////////////////////////////
                                        // this.channel.push("file_transfer_server_to_client", {mid: "180523-161850", sas: event.sas, file_pusher_pid: event.file_pusher_pid});
                                        this.elixir_websocket.channel.send(JSON.stringify({ name:"",
                                                                                            dir:"",
                                                                                            chunk:"",
                                                                                            mid:"180523-161850",
                                                                                            uuid:fileInfo.uuid,
                                                                                            cid:1,
                                                                                            cs:0,
                                                                                            fs:0,
                                                                                            ilc:false,
                                                                                            mcs:0,
                                                                                            cc:1,
                                                                                            ct:"",
                                                                                            at:"",
                                                                                            mt:"",
                                                                                            pms:0,
                                                                                            sas:event.sas,
                                                                                            wm:"",
                                                                                            file_pusher_pid: event.file_pusher_pid}));
                                    }else{
                                        ///////////////////////////////////////////////////////////////////////////////
                                        // <h1>Tell server => to stop and cleanup</h1>
                                        ///////////////////////////////////////////////////////////////////////////////
                                        // alert('event.result >>>>>>>>>>>>>>>' + event.result);
                                        // this.channel.push("file_transfer_server_to_client", {mid: "180612-151040", sas: event.sas, file_pusher_pid: event.file_pusher_pid});
                                        this.elixir_websocket.channel.send(JSON.stringify({ name:"",
                                                                                            dir:"",
                                                                                            chunk:"",
                                                                                            mid:"180612-151040",
                                                                                            uuid:fileInfo.uuid,
                                                                                            cid:1,
                                                                                            cs:0,
                                                                                            fs:0,
                                                                                            ilc:false,
                                                                                            mcs:0,
                                                                                            cc:1,
                                                                                            ct:"",
                                                                                            at:"",
                                                                                            mt:"",
                                                                                            pms:0,
                                                                                            sas:event.sas,
                                                                                            wm:"",
                                                                                            file_pusher_pid: event.file_pusher_pid}));
                                    }
                                }
                            };
                            file_writer.write(path_no_basename, payload, write_to, this.file_list);
                        }
                    }
                }else{

                }       
            // }
            // fileReader_ArrayBuffer.readAsArrayBuffer(event.data);
        }else if(typeof(event.data) === 'string'){
            // alert('event.data >>>>>>>>>>>>>> ' + event.data);
            let msg = JSON.parse(event.data); 
            // alert('msg.mid >>>>> ' + msg.mid);
            // alert('msg.chunk >>>>> ' + msg.chunk);
            // alert('msg.uuid >>>>> ' + msg.uuid);
            // alert('msg.name >>>>> ' + msg.name);
            // alert('msg.dir >>>>> ' + msg.dir);
            // alert('msg.sas >>>>> ' + msg.sas);
            // alert('msg.fs >>>>> ' + msg.fs);
            // alert('msg.mt >>>>> ' + msg.mt);
            // alert('msg.wm >>>>> ' + msg.wm);
            if(msg.mid === "180528-190534"){
                // Phoenix.Channel.push state[:socket], "new_msg", %{mid: "180528-190534",
                //                                                 chunk: "[file transfer done]",
                //                                                 uuid: state[:uuid],
                //                                                 name: state[:name],
                //                                                 dir: state[:dir],
                //                                                 sas: state[:sas],
                //                                                 fs: state[:fs],
                //                                                 mt: state[:mt],
                //                                                 wm: state[:wm],
                //                                                 file_pusher_pid: pid_str}
                this.elixir_websocket.channel.send(event.data);
                if(msg.chunk === '[file transfer done]'){
                    // alert("FTP Done >>>> " + msg.sas);
                    let payload = { mid: msg.mid,
                                    chunk: msg.chunk,
                                    uuid: msg.uuid,
                                    name: msg.name,
                                    dir: msg.dir,
                                    sas: msg.sas,
                                    fs: msg.fs,
                                    mt: msg.mt,
                                    wm: msg.wm,
                                    file_pusher_pid: ""};
                    if(this.onreceive_file_in_mem !== null){
                        this.onreceive_file_in_mem(payload);
                    }
                }else if(msg.chunk === '[file transfer stop]'){
                    // -----------------------------------------------------------------------
                    // <h1>File was overwrited</h1>
                    // 這種情形, [不需要]通知收件人, 因爲最後會有一份最新的到收件人手上
                    // ----------------------------------------------------------------------
                    // alert("FTP Stop >>>> " + payload.name); 
                    // alert("FTP Stop >>>> " + msg.sas);
                }else if(msg.chunk === '[file transfer error][file updated]'){
                    // alert("FTP Error >>>> " + msg.sas);
                    // -----------------------------------------------------------------------
                    // <h1>File was updated</h1>
                    // 這種情形, [不需要]通知收件人, server自己重送最新的一份
                    // ----------------------------------------------------------------------
                    // let file_reader = new File_Reader_180709_202430();
                    // file_reader.read(payload.sas, "mem", this.file_list, false);
                }
            }
        }
    }
}